import { atom, useRecoilValue } from 'recoil';

import { activeSaleIdState } from '../../stores/sales';
import { BillingStateQuery, useBillingStateQuery } from '../../types/generated';

export const billingStateState = atom<
  BillingStateQuery['billingState'] | undefined
>({
  key: 'billingStateState',
  default: undefined,
});

export const useBillingState = () => {
  const activeSaleId = useRecoilValue(activeSaleIdState);

  const billingState = useBillingStateQuery(
    {
      saleId: activeSaleId ?? '',
    },
    {
      enabled: !!activeSaleId,
      refetchOnWindowFocus: false,
      select: (data) => data.billingState,
    }
  );

  return billingState;
};
