import { differenceInDays, format, isToday, isYesterday } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { enUS, fr } from 'date-fns/locale';

import { capitalizeFirstLetter } from './string';

export const transformDate = (
  date: Date,
  locale: string,
  timezone?: string
) => {
  const dateLocale = locale === 'fr' ? fr : enUS;
  if (date) {
    const convertedDate = timezone ? toZonedTime(date, timezone) : date;

    if (isToday(date)) {
      return format(
        convertedDate,
        locale === 'fr' ? "'Auj.,' p" : "'Today,' p",
        {
          locale: dateLocale,
        }
      );
    } else if (isYesterday(date)) {
      return format(
        convertedDate,
        locale === 'fr' ? "'Hier,' p" : "'Yesterday,' p",
        {
          locale: dateLocale,
        }
      );
    }

    return format(
      convertedDate,
      locale === 'fr' ? "d MMM',' p" : "MMM d',' p",
      {
        locale: dateLocale,
      }
    );
  }
  return '';
};

export const transformDateWithoutHours = (
  date: Date,
  locale: string,
  timezone?: string
) => {
  const dateLocale = locale === 'fr' ? fr : enUS;
  if (date) {
    const convertedDate = timezone ? toZonedTime(date, timezone) : date;

    if (isToday(date)) {
      return format(convertedDate, `'${locale === 'fr' ? 'Auj.' : 'Today'}'`, {
        locale: dateLocale,
      });
    } else if (isYesterday(date)) {
      return format(
        convertedDate,
        `'${locale === 'fr' ? 'Hier' : 'Yesterday'}'`,
        {
          locale: dateLocale,
        }
      );
    }
    return format(convertedDate, locale === 'fr' ? 'd MMMM' : 'MMMM d', {
      locale: dateLocale,
    });
  }
  return '';
};

export const transformDateWithoutYear = (
  date: Date,
  locale: string,
  timezone?: string
) => {
  const dateLocale = locale === 'fr' ? fr : enUS;
  if (date) {
    const convertedDate = timezone ? toZonedTime(date, timezone) : date;
    if (differenceInDays(new Date(), date) < 7) {
      if (isToday(date)) {
        return format(convertedDate, 'p', {
          locale: dateLocale,
        });
      } else if (isYesterday(date)) {
        return format(
          convertedDate,
          locale === 'fr' ? "'Hier,' p" : "'Yesterday,' p",
          {
            locale: dateLocale,
          }
        );
      }
      return format(convertedDate, "EEEE',' p", {
        locale: dateLocale,
      });
    }
    return format(
      convertedDate,
      locale === 'fr' ? "d MMM',' p" : "MMM d',' p",
      {
        locale: dateLocale,
      }
    );
  }
  return '';
};

export const transformDateWithYear = (
  date: Date,
  locale: string,
  timezone?: string
) => {
  const dateLocale = locale === 'fr' ? fr : enUS;
  if (date) {
    const convertedDate = timezone ? toZonedTime(date, timezone) : date;
    if (differenceInDays(new Date(), date) < 7) {
      if (isToday(date)) {
        return format(convertedDate, 'p', { locale: dateLocale });
      } else if (isYesterday(date)) {
        return format(
          convertedDate,
          `'${locale === 'fr' ? 'Hier' : 'Yesterday'},' p`,
          {
            locale: dateLocale,
          }
        );
      }
      return format(convertedDate, "EEEE',' p", { locale: dateLocale });
    }
    return format(
      convertedDate,
      locale === 'fr' ? "d MMM yyyy',' p" : "MMM d, yyyy',' p",
      { locale: dateLocale }
    );
  }
  return '';
};

export const transformDateSimple = (
  date: Date,
  locale: string,
  simpleYear?: boolean,
  timezone?: string
) => {
  const dateLocale = locale === 'fr' ? fr : enUS;
  if (date) {
    const convertedDate = timezone ? toZonedTime(date, timezone) : date;
    return format(
      convertedDate,
      `${locale === 'fr' ? 'dd/MM' : 'MM/dd'}/${simpleYear ? 'yy' : 'yyyy'}`,
      { locale: dateLocale }
    );
  }
  return '';
};

export const transformDateSale = (
  date: Date,
  locale: string,
  timezone?: string
) => {
  const dateLocale = locale === 'fr' ? fr : enUS;
  if (date) {
    const convertedDate = timezone ? toZonedTime(date, timezone) : date;
    return capitalizeFirstLetter(
      format(convertedDate, 'MMMM yyyy', { locale: dateLocale })
    );
  }
  return '';
};

export const transformFullDate = (
  date: Date,
  locale: string,
  timezone?: string
) => {
  const dateLocale = locale === 'fr' ? fr : enUS;
  if (date) {
    const convertedDate = timezone ? toZonedTime(date, timezone) : date;
    if (isToday(date)) {
      return format(
        convertedDate,
        locale === 'fr'
          ? "'Aujourd''hui, le' d MMMM yyyy 'à' p"
          : "'Today,' MMMM d, yyyy 'at' p",
        { locale: dateLocale }
      );
    } else if (isYesterday(date)) {
      return format(
        convertedDate,
        locale === 'fr'
          ? "'Hier, le' d MMMM yyyy 'à' p"
          : "'Yesterday,' MMMM d, yyyy 'at' p",
        { locale: dateLocale }
      );
    }
    return format(
      convertedDate,
      locale === 'fr' ? "'Le' d MMMM yyyy 'à' p" : "'On' MMMM d, yyyy 'at' p",
      {
        locale: dateLocale,
      }
    );
  }
  return '';
};

export const transformFullDateSimple = (
  date: Date,
  locale: string,
  timezone?: string
) => {
  const dateLocale = locale === 'fr' ? fr : enUS;
  const convertedDate = timezone ? toZonedTime(date, timezone) : date;
  return format(
    convertedDate,
    locale === 'fr' ? "d MMMM yyyy 'à' p" : "MMMM d, yyyy 'at' p",
    {
      locale: dateLocale,
    }
  );
};

export const formatHour = (date: Date, locale: string, timezone?: string) => {
  const dateLocale = locale === 'fr' ? fr : enUS;
  const convertedDate = timezone ? toZonedTime(date, timezone) : date;
  return format(convertedDate, locale === 'fr' ? "H'h'" : 'h a', {
    locale: dateLocale,
  });
};

export const formatFullDate = (
  date: Date,
  locale: string,
  timezone?: string
) => {
  const dateLocale = locale === 'fr' ? fr : enUS;
  const convertedDate = timezone ? toZonedTime(date, timezone) : date;
  return format(
    convertedDate,
    locale === 'fr' ? 'd MMMM yyyy' : 'MMMM d, yyyy',
    {
      locale: dateLocale,
    }
  );
};

export const formatFullDateAndTime = (
  date: Date,
  locale: string,
  timezone?: string
) => {
  const dateLocale = locale === 'fr' ? fr : enUS;
  const convertedDate = timezone ? toZonedTime(date, timezone) : date;
  return format(
    convertedDate,
    locale === 'fr' ? "d MMMM yyyy H'h'" : 'MMMM d, yyyy h a',
    {
      locale: dateLocale,
    }
  );
};

export const formatDayAndMonth = (
  date: Date,
  locale: string,
  timezone?: string
) => {
  const dateLocale = locale === 'fr' ? fr : enUS;
  const convertedDate = timezone ? toZonedTime(date, timezone) : date;

  return format(convertedDate, locale === 'fr' ? 'd MMM' : 'MMM d', {
    locale: dateLocale,
  });
};

export const formatDayAndFullMonth = (
  date: Date,
  locale: string,
  timezone?: string
) => {
  const dateLocale = locale === 'fr' ? fr : enUS;
  const convertedDate = timezone ? toZonedTime(date, timezone) : date;

  return format(convertedDate, locale === 'fr' ? 'd MMMM' : 'MMMM d', {
    locale: dateLocale,
  });
};

export const formatDateFilter = (date: Date, locale: string) => {
  const dateLocale = locale === 'fr' ? fr : enUS;

  return format(date, locale === 'fr' ? 'dd-MM-yyyy' : 'MM-dd-yyyy', {
    locale: dateLocale,
  });
};

export const formatRange = (startDate: Date, endDate: Date, locale: string) => {
  const dateTimeFormat = new Intl.DateTimeFormat(locale, {
    month: 'short',
    day: 'numeric',
  });
  const parts = dateTimeFormat.formatRangeToParts(startDate, endDate);
  const newParts: Intl.DateTimeRangeFormatPart[] = [];
  for (const part of parts) {
    if (part.type === 'literal' && part.value === '–') {
      newParts.push({
        ...part,
        value: ' – ',
      });
    } else {
      newParts.push(part);
    }
  }
  return newParts.map((part) => part.value).join('');
};

export { enUS, fr };
