import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Icon from '@appchoose/icon';
import { useQueryClient } from '@tanstack/react-query';

import { orderDetailUpdatedIdState } from '../../hooks/use-order-updated-subscription';

export const ModalOrderUpdatedBanner: React.FC = () => {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const queryClient = useQueryClient();

  const orderDetailUpdatedId = useRecoilValue(orderDetailUpdatedIdState);

  if (!orderDetailUpdatedId || orderId !== orderDetailUpdatedId) return null;

  return (
    <button
      onClick={() => {
        queryClient.invalidateQueries({
          queryKey: [
            'order',
            {
              orderId,
            },
          ],
        });
      }}
      type="button"
      className="group sticky top-0 z-20 flex w-full flex-wrap items-center justify-center space-x-2 border border-beige-400 bg-beige-300 py-2 text-orange-600"
    >
      <Icon
        icon="refresh"
        className="rotate-0 transition-transform duration-300 ease-out group-hover:rotate-90"
      />
      <span className="font-semibold">
        {t('order.order_updated_notification.order_updated')}
      </span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="2"
        height="2"
        viewBox="0 0 2 2"
        fill="none"
        className="hidden sm:block"
      >
        <circle cx="1" cy="1" r="1" fill="#F6E1C2" />
      </svg>
      <span className="hidden sm:block">
        {t('order.order_updated_notification.refresh')}
      </span>
    </button>
  );
};
