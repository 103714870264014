import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import { useRecoilState } from 'recoil';

import Button from '@appchoose/button';
import { Form } from '@appchoose/form';
import { SearchableSelectOption } from '@appchoose/searchable-select';

import { Brand, brandState } from '../../stores/brand';
import { StoreRegion, useUpdateSellerMutation } from '../../types/generated';
import { getCountryOptions } from '../../utils/address-options';
import { parsePhoneNumber } from '../../utils/phone';
import { removeAllSpaces } from '../../utils/string';
import { ContactFormFields } from '../brand-info/contact-form-fields';

export type OnBoardingContactFormProps = {
  goToNextStep: () => void;
};

type BrandContactFormData = {
  phone: string;
  email: string;
  phoneCountryCode?: string;
  country?: string;
};

export const OnBoardingContactForm: React.FC<OnBoardingContactFormProps> = ({
  goToNextStep,
}: OnBoardingContactFormProps) => {
  const { t } = useTranslation();
  const [brand, setBrand] = useRecoilState(brandState);

  const { mutateAsync: updateSellerMutation } = useUpdateSellerMutation();

  const phoneCountryOptions = getCountryOptions();

  const parsedDefaultPhoneNumber = parsePhoneNumber(brand?.contact_phone ?? '');

  const defaultPhoneCountry = (): SearchableSelectOption => {
    let country = phoneCountryOptions.find((o) =>
      brand?.store === StoreRegion.Fr ? o.value === 'FR' : o.value === 'US'
    ) as SearchableSelectOption;

    const option = phoneCountryOptions.find(
      (o) => o.value === parsedDefaultPhoneNumber?.country
    );
    if (option) country = option;

    return country;
  };

  const [phoneCountry, setPhoneCountry] = useState<SearchableSelectOption>(
    defaultPhoneCountry()
  );

  const form = useForm<BrandContactFormData>({
    mode: 'onTouched',
    defaultValues: {
      phone: parsedDefaultPhoneNumber?.formatInternational(),
      email: brand?.contact_email ?? '',
    },
  });

  const onSubmit = (data: BrandContactFormData) => {
    onUpdate({
      contact_phone: data.phone,
      contact_email: removeAllSpaces(data.email),
    });
  };

  useUpdateEffect(() => {
    form.setValue('phoneCountryCode', phoneCountry.value);
    form.setValue('country', phoneCountry.label || '', {
      shouldValidate: true,
    });
  }, [phoneCountry]);

  const onUpdate = (
    data: Pick<NonNullable<Brand>, 'contact_phone' | 'contact_email'>
  ) => {
    if (!brand) return;
    updateSellerMutation({ updateSeller: data });
    setBrand({
      ...brand,
      contact_email: data.contact_email,
      contact_phone: data.contact_phone,
    });
    goToNextStep();
  };

  // Rerender on 'phoneCountryCode' change
  form.watch('phoneCountryCode');

  return (
    <section className="sm:col-11 w-full">
      <div className="mt-8 sm:mt-20">
        <h2 className="mb-6 text-2xl font-bold sm:mb-10 sm:text-3.5xl">
          {t('onboarding.contact.title')}
        </h2>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <ContactFormFields
              autoFocus
              phoneCountry={phoneCountry}
              setPhoneCountry={setPhoneCountry}
            />
            <div className="mt-8 flex sm:mt-10">
              <Button type="submit" size="large">
                {t('continue')}
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </section>
  );
};
