import { Trans, useTranslation } from 'react-i18next';

import Alert from '@appchoose/alert';
import Button from '@appchoose/button';
import {
  ModalClose,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@appchoose/modal';

import { OrdersByTrackingQuery } from '../../types/generated-new';
import { track } from '../../utils/analytics';
import { openCrispChat } from '../../utils/crisp';
import { OrderDetails } from './order-details';

type ModalAddTrackingAlreadyAssignedDifferentRecipientProps = {
  detectedOrders: OrdersByTrackingQuery['ordersByTracking'];
};

export const ModalAddTrackingAlreadyAssignedDifferentRecipient: React.FC<
  ModalAddTrackingAlreadyAssignedDifferentRecipientProps
> = ({ detectedOrders }) => {
  const { t } = useTranslation();

  return (
    <div className="flex h-full flex-col overflow-hidden">
      <div className="flex-auto overflow-y-auto p-6 md:p-10">
        <ModalHeader>
          <ModalTitle>
            {t('order.tracking.modal_add_tracking_number.add_tracking_number')}
          </ModalTitle>
        </ModalHeader>
        <div className="space-y-10">
          <Alert
            appearance="default"
            size="small"
            icon="information"
            title={t(
              'order.tracking.modal_add_tracking_number.x_tracking_already_exists',
              { count: detectedOrders.length }
            )}
          >
            <p className="text-sm text-gray-700">
              {t(
                'order.tracking.modal_add_tracking_number.impossible_to_associate_tracking_different_customers'
              )}
            </p>
          </Alert>
          <div className="space-y-2">
            <p className="text-sm text-gray-900">
              <Trans
                t={t}
                i18nKey="order.tracking.x_order_of"
                values={{
                  customer: detectedOrders?.[0]?.recipient?.fullName,
                  count: detectedOrders.length,
                }}
                components={{ b: <b className="text-gray-900" /> }}
              />
            </p>
            <ul className="divide-y divide-gray-300 rounded border border-gray-300">
              {detectedOrders.map((order) => (
                <li key={order.id}>
                  <OrderDetails order={order} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <ModalFooter className="mt-4 justify-between">
        <div className="flex items-center space-x-1 text-sm">
          <span className="text-gray-900">
            {t('order.tracking.modal_add_tracking_number.have_a_problem')}
          </span>
          <button
            type="button"
            className="font-semibold text-green-900"
            onClick={() => {
              track('OpenHelpChat', {
                from: 'ModalTrackingAlreadyAssignedDifferentRecipient',
              });
              openCrispChat();
            }}
          >
            {t('contact_us')}
          </button>
        </div>
        <div className="space-x-6">
          <ModalClose asChild>
            <Button type="button">{t('close')}</Button>
          </ModalClose>
        </div>
      </ModalFooter>
    </div>
  );
};
