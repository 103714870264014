import parsePhoneNumberFromString, {
  CountryCode,
  getExampleNumber,
  isSupportedCountry,
} from 'libphonenumber-js';
import examples from 'libphonenumber-js/mobile/examples';

export const parsePhoneNumber = (phoneNumber: string) => {
  return parsePhoneNumberFromString(phoneNumber);
};

export const formatNationalPhoneNumber = (
  phoneNumber: string,
  countryCode: string
) => {
  let formattedPhoneNumber = phoneNumber;
  const parsedPhoneNumber = parsePhoneNumberFromString(
    phoneNumber || '',
    countryCode as CountryCode
  );

  if (parsedPhoneNumber)
    formattedPhoneNumber = parsedPhoneNumber.formatNational();
  return formattedPhoneNumber;
};

export const formatInternationalPhoneNumber = (
  phoneNumber: string,
  countryCode: string
) => {
  let formattedPhoneNumber = phoneNumber;
  const parsedPhoneNumber = parsePhoneNumberFromString(
    phoneNumber || '',
    countryCode as CountryCode
  );

  if (parsedPhoneNumber)
    formattedPhoneNumber = parsedPhoneNumber.formatInternational();
  return formattedPhoneNumber;
};

export const generatePhonePlaceholder = (countryCode: string) => {
  let placeholder = '';
  if (isSupportedCountry(countryCode)) {
    const phoneNumber = getExampleNumber(countryCode, examples);
    if (phoneNumber) placeholder = phoneNumber.formatNational();
  }
  return placeholder;
};

export const hasPhoneNumberReachedMaxLength = (
  phoneNumber: string,
  countryCode: string
) => {
  const phoneNumberLength = getExampleNumber(
    countryCode as CountryCode,
    examples
  )?.formatNational().length;

  if (!phoneNumberLength) return false;

  const formattedTelValue =
    formatNationalPhoneNumber(phoneNumber, countryCode) || '';

  return formattedTelValue.length >= phoneNumberLength ? true : false;
};
