import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '@appchoose/icon';
import Loader from '@appchoose/loader';
import { toast } from '@appchoose/toast';

import { useGenerateInvoiceCustomsMutation } from '../../types/generated-new';

type ModalOrderInvoiceProps = {
  orderId: string;
};

export const ModalOrderInvoice: React.FC<ModalOrderInvoiceProps> = ({
  orderId,
}) => {
  const { t } = useTranslation();

  const { mutateAsync, isPending, error } = useGenerateInvoiceCustomsMutation();

  useEffect(() => {
    if (error) {
      toast.error(t('order.invoice.generate_invoice_error'));
    }
  }, [error]);

  return (
    <div className="space-y-4">
      <p className="text-xs text-gray-700">
        {t('order.invoice.us_customs_procedures_only')}
      </p>
      {isPending ? (
        <Loader className="size-4" />
      ) : (
        <button
          type="button"
          className="flex gap-x-1 text-xs font-semibold text-green-900"
          onClick={async () => {
            const { generateInvoiceCustoms } = await mutateAsync({
              orderId,
            });
            window.open(generateInvoiceCustoms.url, '_blank');
          }}
        >
          <Icon icon="download" />
          {t('order.invoice.download_invoice')}
        </button>
      )}
    </div>
  );
};
