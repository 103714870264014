import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import Alert from '@appchoose/alert';

import { brandState } from '../../stores/brand';
import { OrderQuery } from '../../types/generated-new';
import { track } from '../../utils/analytics';
import { openCrispChat } from '../../utils/crisp';
import { transformDateWithoutHours } from '../../utils/date';

type ModalOrderDelayAlertProps = {
  order?: OrderQuery['order'];
};

export const ModalOrderDelayAlert: React.FC<ModalOrderDelayAlertProps> = ({
  order,
}: ModalOrderDelayAlertProps) => {
  const brand = useRecoilValue(brandState);
  const { i18n, t } = useTranslation();

  if (
    order?.isShippingEstimatedTimeExceeded &&
    !order?.parcels?.[0]?.trackingNumber &&
    !order?.isFullDigital
  ) {
    return (
      <Alert
        size="small"
        appearance="error"
        icon="clock"
        title={
          <>
            {t('order.delayed')}{' '}
            {transformDateWithoutHours(
              new Date(order?.createdAt ?? ''),
              i18n?.language === 'fr' ? 'fr' : 'en',
              brand?.timezone ?? undefined
            )}
            .
          </>
        }
      >
        <p className="text-sm">
          {t('order.delayed_text')}{' '}
          <button
            type="button"
            onClick={() => {
              track('OpenHelpChat', {
                from: 'ModalOrder',
              });
              openCrispChat();
            }}
            className="underline"
          >
            {t('order.contact_us')}
          </button>
        </p>
      </Alert>
    );
  }

  return null;
};
