import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Alert from '@appchoose/alert';
import Icon from '@appchoose/icon';

import { InvoiceReady } from '../../components/invoice-ready/invoice-ready';
import { SaleInfo } from '../../components/sale-info/sale-info';
import { brandState, serverTimeState } from '../../stores/brand';
import {
  activeSaleIdState,
  activeSaleState,
  isCurrent,
  isEnded,
} from '../../stores/sales';
import { useOrdersCountersQuery } from '../../types/generated-new';
import { BrandMatch } from '../../types/navigation';
import { isInvoiceReady } from '../../utils/billing';
import { transformFullDate } from '../../utils/date';
import { useBillingState } from '../billing-screen/use-billing-state';

export const HomeOrders: React.FC = () => {
  const { search } = useLocation();
  const { brandId = '' } = useParams<BrandMatch>();
  const brand = useRecoilValue(brandState);
  const { i18n, t } = useTranslation();

  const activeSaleId = useRecoilValue(activeSaleIdState);
  const activeSale = useRecoilValue(activeSaleState);
  const serverTime = useRecoilValue(serverTimeState);

  const { data: billingState } = useBillingState();

  const { data: ordersCounterData } = useOrdersCountersQuery(
    {
      sellerId: brandId,
      saleId: activeSaleId ?? '',
    },
    {
      enabled: !!activeSaleId,
      refetchOnWindowFocus: false,
    }
  );

  if (!activeSale) return null;

  if (
    (ordersCounterData?.ordersCounters?.tagging?.brand?.actionRequired ?? 0) > 0
  ) {
    return (
      <section className="flex flex-col space-y-4">
        {isEnded(activeSale, serverTime) ? (
          <Alert
            title={t('home.closed_sale_title')}
            icon="check"
            appearance="success"
            className="w-full"
          >
            <p className="m-0 text-sm text-gray-700">
              {t('home.closed_sale_p')}
            </p>
          </Alert>
        ) : null}
        {brand?.plugins?.shopify && !brand.plugins.shopify.token ? (
          <Alert
            title={t('home.shopify_error_title')}
            icon="shopifyError"
            appearance="error"
            sideContent={
              <NavLink
                to={`/${brandId}/settings/integrations${search}`}
                type="button"
                className="rounded-lg border-2 border-red-350 bg-white px-3.5 py-2 text-sm font-bold text-gray-900 outline-none transition-colors duration-300 hover:border-red-600 hover:text-gray-700 focus-visible:ring-2 focus-visible:ring-red-600/30"
              >
                {t('sidebar.settings')}
              </NavLink>
            }
          >
            <p className="m-0 text-sm text-gray-700">
              {t('home.shopify_error_text')}
            </p>
          </Alert>
        ) : null}
        <div className="flex w-full justify-between rounded-lg border border-gray-300 p-6">
          <div className="flex items-center space-x-3">
            <Icon icon="clock" size="large" className="text-gray-500" />
            <span className="font-semibold text-gray-900">
              {t('home.alerts.orders.order_required_actions', {
                count:
                  ordersCounterData?.ordersCounters?.tagging?.brand
                    ?.actionRequired,
              })}
            </span>
          </div>
          <NavLink
            to={`/${brand?.id ?? ''}/orders?view=required-action`}
            className="px-2 py-1 text-sm font-semibold text-green-900"
          >
            {t('home.alerts.orders.show_orders')}
          </NavLink>
        </div>
      </section>
    );
  }

  if (
    isCurrent(activeSale, serverTime) &&
    (ordersCounterData?.ordersCounters?.tagging?.brand?.actionRequired ?? 0) ===
      0 &&
    (ordersCounterData?.ordersCounters?.tagging?.brand?.available ?? 0) > 0
  ) {
    return (
      <section>
        <div className="flex w-full justify-between rounded-lg border border-gray-300 p-6">
          <div className="flex items-center space-x-3">
            <Icon icon="check" size="large" className="text-gray-500" />
            <span className="font-semibold text-gray-900">
              {t('home.alerts.orders.all_caught_up')}
            </span>
          </div>
          <NavLink
            to={`/${brand?.id ?? ''}/orders?view=available`}
            className="px-2 py-1 text-sm font-semibold text-green-900"
          >
            {t('home.alerts.orders.see_all_orders')}
          </NavLink>
        </div>
      </section>
    );
  }

  if (
    !isCurrent(activeSale, serverTime) &&
    (ordersCounterData?.ordersCounters?.tagging?.brand?.available ?? 0) > 0
  ) {
    if (isInvoiceReady(billingState)) {
      return (
        <section>
          <InvoiceReady />
        </section>
      );
    }
    return (
      <section>
        <SaleInfo
          variant="invoice-waiting"
          title={t('orders.ended_wait')}
          subtitle={t('invoice.wait_text', {
            withdrawalPeriod: billingState?.withdrawalPeriod,
          })}
        >
          {billingState?.editableAt && (
            <p className="time">
              {transformFullDate(
                new Date(billingState.editableAt),
                i18n.language === 'fr' ? 'fr' : 'en',
                brand?.timezone ?? undefined
              )}
            </p>
          )}
        </SaleInfo>
      </section>
    );
  }
  return (
    <section>
      <SaleInfo
        variant="sale-ready"
        title={t('home.no_orders')}
        subtitle={t('home.no_orders_p')}
      />
    </section>
  );
};
