import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'react-use';
import { useRecoilValue } from 'recoil';

import Icon from '@appchoose/icon';
import { toast } from '@appchoose/toast';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import * as Sentry from '@sentry/react';

import { activeSaleState } from '../../stores/sales';
import { amDefaultContact, findContact } from '../../types/choose-contact';

export type InvoiceLockedContentProps = {
  children?: React.ReactNode;
};

export const InvoiceLockedContent: React.FC<InvoiceLockedContentProps> = ({
  children,
}: InvoiceLockedContentProps) => {
  const { t } = useTranslation();
  const activeSale = useRecoilValue(activeSaleState);
  const amContact = findContact(
    activeSale?.logistics_manager ?? undefined,
    amDefaultContact
  );
  const [emailClipboardState, copyEmailToClipboard] = useCopyToClipboard();

  useEffect(() => {
    const { value, error } = emailClipboardState;
    if (value) {
      toast.success(t('order.copy_mail_feedback'));
    }
    if (error) {
      Sentry.captureException(error);
    }
  }, [emailClipboardState, t]);

  return (
    <div className="mt-[12.5rem] flex w-full flex-col items-center gap-y-20">
      <div className="flex flex-col items-center">{children}</div>
      <div className="space-y-4 px-4">
        <div className="w-[33rem] rounded-[0.25rem] border border-gray-100 bg-[#fbfbfb] px-6 py-5">
          <AccordionPrimitive.Root
            type="single"
            collapsible
            defaultValue="billing-conditions"
          >
            <AccordionPrimitive.Item value="billing-conditions">
              <AccordionPrimitive.Trigger className="flex w-full items-center justify-between text-sm font-semibold [&[data-state=open]>svg]:rotate-180">
                <span>
                  {t('invoice.invoice_locked.requirements_to_unlock_invoice')}
                </span>
                <Icon
                  icon="arrowDown"
                  size="large"
                  className="size-[18px] text-gray-700"
                />
              </AccordionPrimitive.Trigger>
              <AccordionPrimitive.Content className="mt-4 text-sm text-gray-700">
                <ul className="list-inside list-disc">
                  <li>{t('invoice.invoice_locked.sale_is_over')}</li>
                  <li>{t('invoice.invoice_locked.all_orders_delivered')}</li>
                  <li>
                    {t('invoice.invoice_locked.withdrawal_period_is_over')}
                  </li>
                  <li>{t('invoice.invoice_locked.all_returns_refunded')}</li>
                  <li>{t('invoice.invoice_locked.all_claims_processed')}</li>
                </ul>
              </AccordionPrimitive.Content>
            </AccordionPrimitive.Item>
          </AccordionPrimitive.Root>
        </div>
        <div className="w-[33rem] rounded-[0.25rem] border border-gray-100 bg-[#fbfbfb] px-6 py-5">
          <AccordionPrimitive.Root type="single" collapsible>
            <AccordionPrimitive.Item value="billing-contact">
              <AccordionPrimitive.Trigger className="flex w-full items-center justify-between text-sm font-semibold [&[data-state=open]>svg]:rotate-180">
                <span>{t('invoice.invoice_locked.contact')}</span>
                <Icon
                  icon="arrowDown"
                  size="large"
                  className="size-[18px] text-gray-700"
                />
              </AccordionPrimitive.Trigger>

              <AccordionPrimitive.Content className="mt-4 text-sm text-gray-700">
                <div className="flex space-x-4">
                  {amContact ? (
                    <img
                      src={`https://public.choose.app/avatars/80x80/${amContact?.avatar}-80x80.png`}
                      srcSet={`https://public.choose.app/avatars/80x80/${amContact?.avatar}-80x80@2x.png 2x,
                            https://public.choose.app/avatars/80x80/${amContact?.avatar}-80x80@3x.png 3x`}
                      className="inline-block size-16 rounded-full ring-1 ring-gray-100"
                      alt={amContact?.firstName}
                    />
                  ) : (
                    <div className="size-16 rounded-full bg-gray-500"></div>
                  )}
                  <div className="flex flex-col justify-center">
                    <div className="pb-[0.13rem] text-xs font-semibold uppercase tracking-wider text-gray-500">
                      {t('invoice.invoice_locked.account_manager')}
                    </div>
                    <div className="pb-[0.12rem] text-base font-semibold">
                      {amContact?.firstName}
                    </div>

                    <button
                      onClick={() => copyEmailToClipboard(amContact.email)}
                      type="button"
                      className="rounded text-sm text-gray-700 focus-visible:ring-2 focus-visible:ring-gray-900/30"
                    >
                      <div className="flex items-center space-x-2">
                        <span>{amContact?.email}</span>

                        <Icon icon="copy" size="small" />
                      </div>
                    </button>
                  </div>
                </div>
              </AccordionPrimitive.Content>
            </AccordionPrimitive.Item>
          </AccordionPrimitive.Root>
        </div>
      </div>
    </div>
  );
};
