import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';
import { createColumnHelper } from '@tanstack/react-table';

import { brandState } from '../../stores/brand';
import { shortActiveSaleIdState } from '../../stores/sales';
import { BillingStateErrorCode } from '../../types/generated';
import { BillingErrorData, BillingErrorTable } from './billing-error-table';
import { useBillingState } from './use-billing-state';

export const BillingLockedErrors: React.FC = () => {
  const brand = useRecoilValue(brandState);
  const { t } = useTranslation();

  const { data: billingState } = useBillingState();

  const shortActiveSaleId = useRecoilValue(shortActiveSaleIdState);

  const columnHelper = createColumnHelper<BillingErrorData>();

  const columns = [
    columnHelper.accessor('errors', {
      header: () => <>{t('billing.errors.errors')}</>,
      cell: ({ row }) => {
        return (
          <button
            type="button"
            {...{
              onClick: row.getToggleExpandedHandler(),
              style: { cursor: 'pointer' },
            }}
            className="flex items-center space-x-2"
          >
            <Icon
              icon="dropdown"
              size="large"
              className={cn('shrink-0 text-gray-900', {
                '-rotate-90': !row.getIsExpanded(),
              })}
            />

            <span className="text-sm font-semibold lowercase text-red-600">
              {row.original.code}
            </span>
            <span className="rounded bg-red-350 px-1 py-0.5 text-xs font-semibold text-red-600">
              {row.original.errors?.length}
            </span>
          </button>
        );
      },
    }),
    columnHelper.accessor('details', {
      header: () => <>{t('billing.errors.details')}</>,
      cell: (info) => (
        <div>
          <div className="flex text-xs font-semibold uppercase tracking-wider text-gray-700 sm:hidden">
            {t('billing.errors.details')}
          </div>
          <div>{info.getValue()}</div>
        </div>
      ),
    }),
    columnHelper.accessor('actions', {
      header: () => <>{t('billing.errors.actions')}</>,
      cell: (info) => (
        <div>
          <div className="flex text-xs font-semibold uppercase tracking-wider text-gray-700 sm:hidden">
            {t('billing.errors.actions')}
          </div>
          <NavLink
            to={`${brand?.id ? '/' + brand.id : ''}/orders${
              info.row.original.actionQueryParams
            }`}
            target="_blank"
            className="flex items-center space-x-1 text-xs font-semibold text-green-900"
            rel="noreferrer"
          >
            <Icon icon="externalLinkDefault" className="shrink-0" />
            <span>{info.getValue()}</span>
          </NavLink>
        </div>
      ),
    }),
    columnHelper.accessor('whoCanResolve', {
      header: () => <>{t('billing.errors.who_can_resolve')}</>,
      cell: (info) => (
        <div>
          <div className="flex text-xs font-semibold uppercase tracking-wider text-gray-700 sm:hidden">
            {t('billing.errors.who_can_resolve')}
          </div>
          <div>{info.getValue()}</div>
        </div>
      ),
    }),
  ];

  const data: BillingErrorData[] = useMemo(() => {
    const data: BillingErrorData[] = [];
    if (
      (billingState?.errors.filter(
        (error) => error.code === BillingStateErrorCode.OrderNotDelivered
      )?.length ?? 0) > 0
    ) {
      data.push({
        actionQueryParams: `?filter=filters&offset=0&shippingFilter=IN_PROGRESS&sort=CREATED_DESC&sid=${shortActiveSaleId}`,
        actions: t('billing.errors.order_not_delivered.actions'),
        code: 'ORDER_NOT_DELIVERED',
        details: t('billing.errors.order_not_delivered.details'),
        errors:
          billingState?.errors.filter(
            (error) => error.code === BillingStateErrorCode.OrderNotDelivered
          ) ?? [],
        type: 'error',
        whoCanResolve: t('billing.errors.order_not_delivered.who_can_resolve'),
      });
    }
    if (
      (billingState?.errors.filter(
        (error) => error.code === BillingStateErrorCode.ReturnNotValidated
      )?.length ?? 0) > 0
    ) {
      data.push({
        actionQueryParams: `?filter=filters&offset=0&returnFilter=PENDING&sort=CREATED_DESC&sid=${shortActiveSaleId}`,
        actions: t('billing.errors.return_not_validated.actions'),
        code: 'RETURN_NOT_VALIDATED',
        details: t('billing.errors.return_not_validated.details'),
        errors:
          billingState?.errors.filter(
            (error) => error.code === BillingStateErrorCode.ReturnNotValidated
          ) ?? [],
        type: 'error',
        whoCanResolve: t('billing.errors.return_not_validated.who_can_resolve'),
      });
    }
    if (
      (billingState?.errors.filter(
        (error) => error.code === BillingStateErrorCode.ClaimUnfulfilled
      )?.length ?? 0) > 0
    ) {
      data.push({
        actionQueryParams: `?filter=filters&offset=0&claimFilter=PENDING&sort=CREATED_DESC&sid=${shortActiveSaleId}`,
        actions: t('billing.errors.claim_unfulfilled.actions'),
        code: 'CLAIM_UNFULFILLED',
        details: t('billing.errors.claim_unfulfilled.details'),
        errors:
          billingState?.errors.filter(
            (error) => error.code === BillingStateErrorCode.ClaimUnfulfilled
          ) ?? [],
        type: 'error',
        whoCanResolve: t('billing.errors.claim_unfulfilled.who_can_resolve'),
      });
    }

    return data;
  }, [billingState, shortActiveSaleId, t]);

  if (data.length === 0) return null;

  return (
    <div className="mb-10 w-full max-w-4xl">
      <BillingErrorTable columns={columns} data={data} />
    </div>
  );
};
