import React, { ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import TelInput from 'react-phone-number-input/input';

import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';
import SearchableSelect, {
  SearchableSelectButtonProps,
  SearchableSelectOption,
} from '@appchoose/searchable-select';
import { CountryCode } from 'libphonenumber-js';

import { searchCountry } from '../../utils/address';
import { getCountryOptions } from '../../utils/address-options';
import {
  generatePhonePlaceholder,
  hasPhoneNumberReachedMaxLength,
} from '../../utils/phone';

const SearchableSelectButton = forwardRef(
  <T extends SearchableSelectOption>(
    { value, isOpen, toggleOpen }: SearchableSelectButtonProps<T>,
    ref: ForwardedRef<HTMLButtonElement>
  ) => (
    <button
      ref={ref}
      type="button"
      onClick={toggleOpen}
      className={`flex items-center rounded-l rounded-r-none border border-gray-500 bg-gray-50 px-2 py-[0.6875rem] text-sm leading-5.5 duration-300 focus:z-10 focus:ring-1 focus:ring-gray-700 group-hover:border-r group-hover:border-gray-700 ${
        isOpen ? 'relative z-40 ring-1 ring-gray-700' : 'z-10'
      }`}
    >
      {value?.leftSection ? value.leftSection : null}
      <Icon icon={isOpen ? 'close' : 'arrowDown'} />
    </button>
  )
);

SearchableSelectButton.displayName = 'SearchableSelectButton';

const PhoneCountryNoResult: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="px-4 py-2 text-sm text-gray-500">
      {t('address.fields.country.no_results')}
    </div>
  );
};

type PhoneInputProps = {
  onChange?: (value: string) => void;
  phoneCountry: SearchableSelectOption;
  setPhoneCountry: (newCountry: SearchableSelectOption) => void;
  value?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const PhoneInput = forwardRef(
  (
    { className, phoneCountry, setPhoneCountry, ...props }: PhoneInputProps,
    ref
  ) => {
    const { t } = useTranslation();
    const phoneCountryOptions = getCountryOptions();

    return (
      <div className="group flex w-full items-center">
        <SearchableSelect
          defaultValue={phoneCountry}
          placeholder={t('address.fields.country.placeholder')}
          noResults={PhoneCountryNoResult}
          emptyValue={t('address.fields.country.empty_field')}
          search={(term: string) =>
            searchCountry(term, phoneCountryOptions, phoneCountryOptions)
          }
          onSelect={(selectedCountry: SearchableSelectOption) => {
            setPhoneCountry(selectedCountry);
          }}
          SearchableSelectButton={SearchableSelectButton}
        />

        <TelInput
          ref={ref}
          autoComplete="tel"
          className={cn(
            'form-input -ml-px block w-full rounded rounded-l-none border border-gray-500 p-2 text-sm leading-5.5 outline-none transition duration-300 hover:border-gray-700 focus:z-10 focus:border-gray-700 focus:ring-gray-700 aria-[invalid="true"]:border-red-600 aria-[invalid="true"]:ring-red-600',
            className
          )}
          defaultCountry={phoneCountry.value as CountryCode}
          initialvalueformat="national"
          placeholder={generatePhonePlaceholder(phoneCountry.value)}
          {...props}
          onChange={(value) => props.onChange?.(value ?? '')}
          onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (
              hasPhoneNumberReachedMaxLength(
                props.value ?? '',
                phoneCountry.value
              )
            )
              event.preventDefault();
          }}
        />
      </div>
    );
  }
);

PhoneInput.displayName = 'PhoneInput';
