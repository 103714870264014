import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import Alert from '@appchoose/alert';
import Button from '@appchoose/button';
import { Form, FormField } from '@appchoose/form';
import Loader from '@appchoose/loader';
import {
  ModalClose,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@appchoose/modal';
import { toast } from '@appchoose/toast';
import { useQueryClient } from '@tanstack/react-query';

import { activeSaleState } from '../../stores/sales';
import {
  OrderQuery,
  OrdersByTrackingQuery,
  useDetectCouriersQuery,
  useGroupOrderParcelMutation,
  useOrderQuery,
  useOrdersByTrackingQuery,
  useSwapOrderParcelMutation,
  useUpdateOrderParcelMutation,
} from '../../types/generated-new';
import { track } from '../../utils/analytics';
import { openCrispChat } from '../../utils/crisp';
import { Courier } from '../icons/courier';
import { Exchange } from '../icons/exchange';
import { OrderDetails } from './order-details';
import { OrderDetailsCheckbox } from './order-details-checkbox';
import { OrderDetailsSwap } from './order-details-swap';
import { OrderTrackingUpdate } from './order-tracking-update';
import { getDetectCourierError } from './tracking-info-form';
import {
  TrackingNumberFormFields,
  searchCourierState,
  selectedCourierState,
} from './tracking-number-form-fields';

enum EditStep {
  CONFIRM,
  EDIT_MULTIPLE,
  EDIT_MULTIPLE_CONFIRM,
  GROUP,
  GROUP_CHOOSE,
  GROUP_CHOOSE_CONFIRM,
  SWAP,
  TRACKING,
}

type ModalOrderEditTrackingProps = {
  parcel?:
    | OrderQuery['order']['parcels'][0]
    | OrderQuery['order']['returnParcels'][0];
  onConfirm: () => void;
};

export type TrackingInfoFormData = {
  trackingCourierSlug: string;
  trackingNumber: string;
  orders: {
    order: NonNullable<
      NonNullable<OrdersByTrackingQuery['ordersByTracking']>[number]
    >;
    selected: boolean;
  }[];
};

export const ModalOrderEditTracking: React.FC<ModalOrderEditTrackingProps> = ({
  parcel,
  onConfirm,
}) => {
  const queryClient = useQueryClient();
  const { orderId } = useParams();
  const activeSale = useRecoilValue(activeSaleState);
  const { t } = useTranslation();
  const setSearchCourier = useSetRecoilState(searchCourierState);
  const [selectedCourier, setSelectedCourier] =
    useRecoilState(selectedCourierState);
  const [step, setStep] = useState<EditStep>(EditStep.TRACKING);

  const form = useForm<TrackingInfoFormData>({
    mode: 'onTouched',
    defaultValues: {
      trackingCourierSlug: parcel?.trackingCourier.slug ?? '',
      trackingNumber: '',
    },
  });
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'orders',
  });

  useEffect(() => {
    if (parcel?.trackingCourier) {
      setSearchCourier(parcel?.trackingCourier?.name ?? '');
      setSelectedCourier(parcel?.trackingCourier);
    }
  }, []);

  const {
    data: detectCouriersData,
    isFetching: isDetectCouriersFetching,
    refetch: refetchDetectCouriers,
  } = useDetectCouriersQuery(
    {
      input: {
        orderId: orderId ?? '',
        trackingNumber: form.getValues('trackingNumber') ?? '',
      },
    },
    {
      select: (data) => data.detectCouriers,
      enabled: false,
    }
  );
  const {
    data: originOrdersByTrackingData,
    isFetching: isOriginOrdersByTrackingFetching,
    refetch: refetchOriginOrdersByTracking,
  } = useOrdersByTrackingQuery(
    {
      input: {
        saleId: activeSale?.id ?? '',
        trackingCourierSlug: parcel?.trackingCourier?.slug ?? '',
        trackingNumber: parcel?.trackingNumber ?? '',
      },
    },
    {
      select: (data) => data.ordersByTracking,
      enabled: false,
    }
  );
  const {
    data: destinationOrdersByTrackingData,
    isFetching: isDestinationOrdersByTrackingFetching,
    refetch: refetchDestinationOrdersByTracking,
  } = useOrdersByTrackingQuery(
    {
      input: {
        saleId: activeSale?.id ?? '',
        trackingCourierSlug: form.getValues('trackingCourierSlug') ?? '',
        trackingNumber: form.getValues('trackingNumber') ?? '',
      },
    },
    {
      select: (data) => data.ordersByTracking,
      enabled: false,
    }
  );
  const { data: order } = useOrderQuery(
    {
      orderId: orderId ?? '',
    },
    {
      enabled: !!orderId,
      refetchOnWindowFocus: false,
      select: (result) => result.order,
    }
  );
  const {
    mutateAsync: mutateGroupOrderParcel,
    isPending: isGroupOrderParcelPending,
    error: groupOrderParcelError,
    reset: resetGroupOrderParcel,
  } = useGroupOrderParcelMutation();
  const {
    mutateAsync: mutateSwapOrderParcel,
    isPending: isSwapOrderParcelPending,
    error: swapOrderParcelError,
    reset: resetSwapOrderParcel,
  } = useSwapOrderParcelMutation();
  const {
    mutateAsync: mutateUpdateOrderParcel,
    isPending: isUpdateOrderParcelPending,
    error: updateOrderParcelError,
    reset: resetUpdateOrderParcel,
  } = useUpdateOrderParcelMutation();

  const onSubmit = async (data: TrackingInfoFormData) => {
    const detectCouriersResult = await refetchDetectCouriers();

    if (detectCouriersResult.data === undefined) return;
    const detectCourierError = getDetectCourierError(
      data.trackingCourierSlug,
      detectCouriersResult.data
    );
    if (!detectCourierError) {
      fetchOrdersByTrackingAndSetStep();
    }
  };

  const fetchOrdersByTrackingAndSetStep = async () => {
    const originData = await refetchOriginOrdersByTracking();
    const originOrders = originData.data?.filter(
      (order) => order.id !== orderId
    );
    const destinationData = await refetchDestinationOrdersByTracking();
    const destinationOrders = destinationData.data;

    if (originOrders?.length === 0 && destinationOrders?.length === 0) {
      setStep(EditStep.CONFIRM);
      return;
    }

    // Modification de plusieurs commandes du même colis
    if ((originOrders?.length ?? 0) > 0 && destinationOrders?.length === 0) {
      originOrders?.map((order) =>
        append({
          order: order,
          selected: true,
        })
      );
      setStep(EditStep.EDIT_MULTIPLE);
      return;
    }

    // Regroupement de commandes
    if (
      destinationOrders?.every(
        (o) =>
          o.customerId === order?.customerId &&
          o.recipient.id === order?.recipient.id
      )
    ) {
      if (originOrders?.length === 0) {
        setStep(EditStep.GROUP);
        return;
      }

      if ((originOrders?.length ?? 0) > 0) {
        originOrders?.map((order) =>
          append({
            order: order,
            selected: true,
          })
        );
        setStep(EditStep.GROUP_CHOOSE);
        return;
      }
    } else {
      // Inversion de commandes
      setStep(EditStep.SWAP);
      return;
    }
  };

  const groupOrderParcel = async () => {
    try {
      track('EditTrackingGroupOrders', {});

      await mutateGroupOrderParcel({
        input: {
          orderId: orderId ?? '',
          orderIdsExcluded: form
            .getValues('orders')
            .filter((o) => !o.selected)
            .map((o) => o.order.id),
          trackingInfo: {
            trackingCourierSlug: form.getValues('trackingCourierSlug') ?? '',
            trackingNumber: form.getValues('trackingNumber') ?? '',
          },
        },
      });

      queryClient.invalidateQueries({
        queryKey: ['order', { orderId: orderId }],
      });

      toast.success(t('order.tracking.tracking_edited'));

      onConfirm();
    } catch (error) {
      // Do nothing
    }
  };

  const swapOrderParcel = async () => {
    try {
      track('EditTrackingSwapOrders', {});

      await mutateSwapOrderParcel({
        input: {
          orderId: orderId ?? '',
          trackingInfo: {
            trackingCourierSlug: form.getValues('trackingCourierSlug') ?? '',
            trackingNumber: form.getValues('trackingNumber') ?? '',
          },
        },
      });

      queryClient.invalidateQueries({
        queryKey: ['order', { orderId: orderId }],
      });

      toast.success(t('order.tracking.tracking_edited'));

      onConfirm();
    } catch (error) {
      // Do nothing
    }
  };

  const updateOrderParcel = async () => {
    try {
      track('EditTrackingUpdateOrders', {});

      await mutateUpdateOrderParcel({
        input: {
          orderId: orderId ?? '',
          trackingInfo: {
            trackingCourierSlug: form.getValues('trackingCourierSlug') ?? '',
            trackingNumber: form.getValues('trackingNumber') ?? '',
          },
          orderIdsExcluded: form
            .getValues('orders')
            .filter((o) => o.order.id !== (orderId ?? ''))
            .filter((o) => !o.selected)
            .map((o) => o.order.id),
        },
      });

      queryClient.invalidateQueries({
        queryKey: ['order', { orderId: orderId }],
      });

      toast.success(t('order.tracking.tracking_edited'));

      onConfirm();
    } catch (error) {
      // Do nothing
    }
  };

  let appearance: 'warning' | 'danger' | undefined = undefined;
  if (
    getDetectCourierError(
      form.getValues('trackingCourierSlug'),
      detectCouriersData
    )
  ) {
    appearance = 'warning';
  }

  const goToTracking = () => {
    setStep(EditStep.TRACKING);
    fields.map((_, index) => {
      remove(index);
    });
  };

  return (
    <div className="flex h-full flex-col overflow-hidden">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex h-full flex-col"
        >
          {step === EditStep.TRACKING ? (
            <>
              <div className="flex-auto overflow-y-auto p-6 md:p-10">
                <ModalHeader>
                  <ModalTitle>
                    {t(
                      'order.tracking.modal_edit_tracking_number.edit_tracking_number'
                    )}
                  </ModalTitle>
                </ModalHeader>
                <div className="space-y-10">
                  <div className="space-y-4">
                    <p className="text-sm text-gray-700">
                      {t(
                        'order.tracking.modal_edit_tracking_number.customer_notified_of_change'
                      )}
                      <br />
                      {t(
                        'order.tracking.modal_edit_tracking_number.please_limit_your_changes'
                      )}
                    </p>
                    <div className="divide-y divide-gray-300 rounded border border-gray-300">
                      <OrderDetails
                        order={{
                          id: orderId ?? '',
                          recipient: {
                            fullName: order?.recipient?.fullName ?? '',
                          },
                          parcels: [
                            {
                              trackingCourier: {
                                logoUrl: parcel?.trackingCourier?.logoUrl ?? '',
                                name: parcel?.trackingCourier?.name ?? '',
                              },
                              trackingNumber: parcel?.trackingNumber ?? '',
                            },
                          ],
                        }}
                        size="large"
                      />
                    </div>
                  </div>
                  <TrackingNumberFormFields
                    appearance={appearance}
                    detectedCouriers={detectCouriersData}
                    onReset={() => {
                      resetGroupOrderParcel();
                      resetSwapOrderParcel();
                      resetUpdateOrderParcel();
                    }}
                    previousValues={{
                      trackingCourierSlug: parcel?.trackingCourier?.slug ?? '',
                      trackingNumber: parcel?.trackingNumber ?? '',
                    }}
                  />
                </div>
              </div>
              <ModalFooter className="justify-between">
                <div className="flex items-center space-x-1 text-sm">
                  <span className="text-gray-900">
                    {t(
                      'order.tracking.modal_add_tracking_number.have_a_problem'
                    )}
                  </span>
                  <button
                    type="button"
                    className="font-semibold text-green-900"
                    onClick={() => {
                      track('OpenHelpChat', {
                        from: 'ModalOrderEditTracking',
                      });
                      openCrispChat();
                    }}
                  >
                    {t('contact_us')}
                  </button>
                </div>
                <div className="flex space-x-6">
                  <ModalClose asChild>
                    <Button type="button" appearance="outline">
                      {t('cancel')}
                    </Button>
                  </ModalClose>

                  {isDetectCouriersFetching ||
                  isOriginOrdersByTrackingFetching ||
                  isDestinationOrdersByTrackingFetching ? (
                    <div className="flex items-center justify-center space-x-2 p-2 text-sm">
                      <Loader className="size-4" />
                      <span className="text-gray-700">
                        {t('order.tracking.check_compatibility')}
                      </span>
                    </div>
                  ) : detectCouriersData ? (
                    <Button
                      type="button"
                      onClick={() => fetchOrdersByTrackingAndSetStep()}
                      disabled={isDetectCouriersFetching}
                    >
                      {t('order.tracking.modal_edit_tracking_number.next')}
                    </Button>
                  ) : (
                    <Button type="submit" disabled={isDetectCouriersFetching}>
                      {t('order.tracking.modal_edit_tracking_number.next')}
                    </Button>
                  )}
                </div>
              </ModalFooter>
            </>
          ) : null}
          {step === EditStep.CONFIRM ? (
            <>
              <div className="flex-auto overflow-y-auto p-6 md:p-10">
                <ModalHeader>
                  <ModalTitle>
                    {t(
                      'order.tracking.modal_edit_tracking_number.confirm_tracking_change'
                    )}
                  </ModalTitle>
                </ModalHeader>
                <div className="space-y-10">
                  <div className="space-y-4">
                    <p className="text-sm text-gray-700">
                      {t(
                        'order.tracking.modal_edit_tracking_number.customer_notified_of_change_after_confirmation'
                      )}
                    </p>
                    {updateOrderParcelError ? (
                      <Alert
                        appearance="error"
                        size="small"
                        icon="alert"
                        title={t(
                          'order.tracking.modal_edit_tracking_number.tracking_cannot_be_changed'
                        )}
                      >
                        <p className="text-sm text-gray-700">
                          {t(
                            'order.tracking.modal_edit_tracking_number.due_to_incompatibility'
                          )}
                        </p>
                      </Alert>
                    ) : null}
                  </div>
                  <OrderTrackingUpdate
                    from={{
                      parcels: [
                        {
                          trackingCourier: {
                            logoUrl: parcel?.trackingCourier?.logoUrl ?? '',
                            name: parcel?.trackingCourier?.name ?? '',
                          },
                          trackingNumber: parcel?.trackingNumber ?? '',
                        },
                      ],
                    }}
                    to={
                      (destinationOrdersByTrackingData?.length ?? 0) > 0
                        ? (destinationOrdersByTrackingData?.map((order) => ({
                            id: order.id,
                            parcels: [
                              {
                                trackingCourier: {
                                  logoUrl:
                                    order.parcels?.[0]?.trackingCourier
                                      ?.logoUrl ?? '',
                                  name:
                                    order.parcels?.[0]?.trackingCourier?.name ??
                                    '',
                                },
                                trackingNumber:
                                  form.getValues('trackingNumber') ?? '',
                              },
                            ],
                          })) ?? [])
                        : [
                            {
                              parcels: [
                                {
                                  trackingCourier: {
                                    logoUrl: selectedCourier?.logoUrl ?? '',
                                    name: selectedCourier?.name ?? '',
                                  },
                                  trackingNumber:
                                    form.getValues('trackingNumber') ?? '',
                                },
                              ],
                            },
                          ]
                    }
                    updateError={!!updateOrderParcelError}
                  />
                </div>
              </div>
              <ModalFooter className="justify-between">
                <div className="flex items-center space-x-1 text-sm">
                  <span className="text-gray-900">
                    {t(
                      'order.tracking.modal_add_tracking_number.have_a_problem'
                    )}
                  </span>
                  <button
                    type="button"
                    className="font-semibold text-green-900"
                    onClick={() => {
                      track('OpenHelpChat', {
                        from: 'ModalOrderEditTracking',
                      });
                      openCrispChat();
                    }}
                  >
                    {t('contact_us')}
                  </button>
                </div>
                <div className="flex space-x-6">
                  <Button
                    type="button"
                    appearance="outline"
                    onClick={() => goToTracking()}
                  >
                    {t('previous')}
                  </Button>

                  {isUpdateOrderParcelPending ? (
                    <div className="flex items-center justify-center space-x-2 p-2 text-sm">
                      <Loader className="size-4" />
                      <span className="text-gray-700">
                        {t(
                          'order.tracking.modal_edit_tracking_number.changes_in_progress'
                        )}
                      </span>
                    </div>
                  ) : (
                    <Button
                      type="button"
                      onClick={() => updateOrderParcel()}
                      disabled={isUpdateOrderParcelPending}
                    >
                      {t(
                        'order.tracking.modal_edit_tracking_number.confirm_change'
                      )}
                    </Button>
                  )}
                </div>
              </ModalFooter>
            </>
          ) : null}
          {step === EditStep.EDIT_MULTIPLE ? (
            <>
              <div className="flex-auto overflow-y-auto p-6 md:p-10">
                <ModalHeader>
                  <ModalTitle>
                    {t(
                      'order.tracking.modal_edit_tracking_number.confirm_tracking_change'
                    )}
                  </ModalTitle>
                </ModalHeader>
                <div className="space-y-10">
                  <OrderTrackingUpdate
                    from={{
                      id: orderId ?? '',
                      parcels: [
                        {
                          trackingCourier: {
                            logoUrl: parcel?.trackingCourier?.logoUrl ?? '',
                            name: parcel?.trackingCourier?.name ?? '',
                          },
                          trackingNumber: parcel?.trackingNumber ?? '',
                        },
                      ],
                    }}
                    to={
                      (destinationOrdersByTrackingData?.length ?? 0) > 0
                        ? (destinationOrdersByTrackingData?.map((order) => ({
                            id: order.id,
                            parcels: [
                              {
                                trackingCourier: {
                                  logoUrl:
                                    order.parcels?.[0]?.trackingCourier
                                      ?.logoUrl ?? '',
                                  name:
                                    order.parcels?.[0]?.trackingCourier?.name ??
                                    '',
                                },
                                trackingNumber:
                                  form.getValues('trackingNumber') ?? '',
                              },
                            ],
                          })) ?? [])
                        : [
                            {
                              id: orderId ?? '',
                              parcels: [
                                {
                                  trackingCourier: {
                                    logoUrl: selectedCourier?.logoUrl ?? '',
                                    name: selectedCourier?.name ?? '',
                                  },
                                  trackingNumber:
                                    form.getValues('trackingNumber') ?? '',
                                },
                              ],
                            },
                          ]
                    }
                    updateError={!!updateOrderParcelError}
                  />
                  <div className="space-y-2">
                    <p className="text-sm text-gray-900">
                      <Trans
                        t={t}
                        i18nKey="order.tracking.x_other_order_of_same_parcel"
                        values={{
                          customer:
                            originOrdersByTrackingData?.[0]?.recipient
                              ?.fullName,
                          count: originOrdersByTrackingData?.length,
                        }}
                        components={{ b: <b className="text-gray-900" /> }}
                      />
                    </p>
                    <ul className="space-y-2">
                      {fields.map((field, index) => (
                        <li key={field.id}>
                          <FormField
                            control={form.control}
                            name={`orders.${index}.selected`}
                            render={({ field: f }) => (
                              <OrderDetailsCheckbox
                                order={field.order}
                                {...f}
                                value=""
                                checked={f.value}
                                onCheckedChange={f.onChange}
                              />
                            )}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <ModalFooter className="justify-between">
                <div className="flex items-center space-x-1 text-sm">
                  <span className="text-gray-900">
                    {t(
                      'order.tracking.modal_add_tracking_number.have_a_problem'
                    )}
                  </span>
                  <button
                    type="button"
                    className="font-semibold text-green-900"
                    onClick={() => {
                      track('OpenHelpChat', {
                        from: 'ModalOrderEditTracking',
                      });
                      openCrispChat();
                    }}
                  >
                    {t('contact_us')}
                  </button>
                </div>
                <div className="flex space-x-6">
                  <Button
                    type="button"
                    appearance="outline"
                    onClick={() => goToTracking()}
                  >
                    {t('previous')}
                  </Button>

                  <Button
                    type="button"
                    onClick={() => setStep(EditStep.EDIT_MULTIPLE_CONFIRM)}
                  >
                    {t('order.tracking.modal_edit_tracking_number.next')}
                  </Button>
                </div>
              </ModalFooter>
            </>
          ) : null}
          {step === EditStep.EDIT_MULTIPLE_CONFIRM ? (
            <>
              <div className="flex-auto overflow-y-auto p-6 md:p-10">
                <ModalHeader>
                  <ModalTitle>
                    {t(
                      'order.tracking.modal_edit_tracking_number.confirm_tracking_change'
                    )}
                  </ModalTitle>
                </ModalHeader>
                <div className="space-y-10">
                  <div className="space-y-4">
                    <p className="text-sm text-gray-700">
                      {t(
                        'order.tracking.modal_edit_tracking_number.customer_notified_of_change_after_confirmation'
                      )}
                    </p>
                    {updateOrderParcelError ? (
                      <Alert
                        appearance="error"
                        size="small"
                        icon="alert"
                        title={t(
                          'order.tracking.modal_edit_tracking_number.tracking_cannot_be_changed'
                        )}
                      >
                        <p className="text-sm text-gray-700">
                          {t(
                            'order.tracking.modal_edit_tracking_number.due_to_incompatibility'
                          )}
                        </p>
                      </Alert>
                    ) : null}
                  </div>
                  <OrderTrackingUpdate
                    from={{
                      id: orderId ?? '',
                      parcels: [
                        {
                          trackingCourier: {
                            logoUrl: parcel?.trackingCourier?.logoUrl ?? '',
                            name: parcel?.trackingCourier?.name ?? '',
                          },
                          trackingNumber: parcel?.trackingNumber ?? '',
                        },
                      ],
                    }}
                    to={[
                      {
                        id: orderId ?? '',
                        parcels: [
                          {
                            trackingCourier: {
                              logoUrl: selectedCourier?.logoUrl ?? '',
                              name: selectedCourier?.name ?? '',
                            },
                            trackingNumber:
                              form.getValues('trackingNumber') ?? '',
                          },
                        ],
                      },
                    ]}
                    updateError={!!updateOrderParcelError}
                  />
                  {form
                    .getValues('orders')
                    .filter((order) => order.selected)
                    .map((order) => (
                      <OrderTrackingUpdate
                        key={order.order.id}
                        from={{
                          id: order.order.id,
                          parcels: [
                            {
                              trackingCourier: {
                                logoUrl:
                                  order.order.parcels?.[0]?.trackingCourier
                                    ?.logoUrl ?? '',
                                name:
                                  order.order.parcels?.[0]?.trackingCourier
                                    ?.name ?? '',
                              },
                              trackingNumber: parcel?.trackingNumber ?? '',
                            },
                          ],
                        }}
                        to={[
                          {
                            id: order.order.id ?? '',
                            parcels: [
                              {
                                trackingCourier: {
                                  logoUrl: selectedCourier?.logoUrl ?? '',
                                  name: selectedCourier?.name ?? '',
                                },
                                trackingNumber:
                                  form.getValues('trackingNumber') ?? '',
                              },
                            ],
                          },
                        ]}
                        updateError={!!updateOrderParcelError}
                      />
                    ))}
                </div>
              </div>
              <ModalFooter className="justify-between">
                <div className="flex items-center space-x-1 text-sm">
                  <span className="text-gray-900">
                    {t(
                      'order.tracking.modal_add_tracking_number.have_a_problem'
                    )}
                  </span>
                  <button
                    type="button"
                    className="font-semibold text-green-900"
                    onClick={() => {
                      track('OpenHelpChat', {
                        from: 'ModalOrderEditTracking',
                      });
                      openCrispChat();
                    }}
                  >
                    {t('contact_us')}
                  </button>
                </div>
                <div className="flex space-x-6">
                  <Button
                    type="button"
                    appearance="outline"
                    onClick={() => setStep(EditStep.EDIT_MULTIPLE)}
                  >
                    {t('previous')}
                  </Button>

                  {isUpdateOrderParcelPending ? (
                    <div className="flex items-center justify-center space-x-2 p-2 text-sm">
                      <Loader className="size-4" />
                      <span className="text-gray-700">
                        {t(
                          'order.tracking.modal_edit_tracking_number.changes_in_progress'
                        )}
                      </span>
                    </div>
                  ) : (
                    <Button
                      type="button"
                      onClick={() => updateOrderParcel()}
                      disabled={isUpdateOrderParcelPending}
                    >
                      {t(
                        'order.tracking.modal_edit_tracking_number.confirm_change'
                      )}
                    </Button>
                  )}
                </div>
              </ModalFooter>
            </>
          ) : null}
          {step === EditStep.GROUP ? (
            <>
              <div className="flex-auto overflow-y-auto p-6 md:p-10">
                <ModalHeader>
                  <ModalTitle>
                    {t(
                      'order.tracking.modal_edit_tracking_number.confirm_edit_tracking_number_and_grouping'
                    )}
                  </ModalTitle>
                </ModalHeader>
                <div className="space-y-10">
                  <Alert
                    appearance="default"
                    size="small"
                    icon="information"
                    title={t(
                      'order.tracking.modal_add_tracking_number.another_order_same_customer_same_tracking'
                    )}
                  >
                    <p className="text-sm text-gray-700">
                      {t(
                        'order.tracking.modal_add_tracking_number.orders_grouped_together_same_parcel'
                      )}
                    </p>
                  </Alert>

                  {groupOrderParcelError ? (
                    <Alert
                      appearance="error"
                      size="small"
                      icon="alert"
                      title={t(
                        'order.tracking.modal_edit_tracking_number.tracking_cannot_be_changed'
                      )}
                    >
                      <p className="text-sm text-gray-700">
                        {t(
                          'order.tracking.modal_edit_tracking_number.due_to_incompatibility'
                        )}
                      </p>
                    </Alert>
                  ) : null}

                  <div className="space-y-2">
                    <p className="text-sm text-gray-900">
                      <Trans
                        t={t}
                        i18nKey="order.tracking.x_order_of"
                        values={{
                          customer:
                            destinationOrdersByTrackingData?.[0]?.recipient
                              ?.fullName,
                          count: destinationOrdersByTrackingData?.length,
                        }}
                        components={{ b: <b className="text-gray-900" /> }}
                      />
                    </p>
                    <div className="divide-y divide-gray-300 rounded border border-gray-300">
                      <OrderDetails
                        order={{
                          id: order?.id,
                          parcels: [
                            {
                              trackingCourier: {
                                logoUrl:
                                  order?.parcels?.[0]?.trackingCourier
                                    ?.logoUrl ?? '',
                                name:
                                  order?.parcels?.[0]?.trackingCourier?.name ??
                                  '',
                              },
                              trackingNumber:
                                order?.parcels?.[0]?.trackingNumber ?? '',
                            },
                          ],
                        }}
                        orderIdStyle="success"
                      />
                    </div>
                    <div className="flex justify-end">
                      <div className="-my-6 mr-4 inline-flex rounded-full border border-[#C6E7D5] bg-[#ECF7F1] p-3">
                        <Courier className="text-green-600" />
                      </div>
                    </div>
                    <ul className="divide-y divide-gray-300 rounded border border-gray-300">
                      {destinationOrdersByTrackingData?.map((order) => (
                        <li key={order.id}>
                          <OrderDetails
                            order={{
                              id: order.id,
                              parcels: [
                                {
                                  trackingCourier: {
                                    logoUrl:
                                      order.parcels?.[0]?.trackingCourier
                                        ?.logoUrl ?? '',
                                    name:
                                      order.parcels?.[0]?.trackingCourier
                                        ?.name ?? '',
                                  },
                                  trackingNumber:
                                    order.parcels?.[0].trackingNumber ?? '',
                                },
                              ],
                            }}
                            showTrackingLink
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <ModalFooter className="justify-between">
                <div className="flex items-center space-x-1 text-sm">
                  <span className="text-gray-900">
                    {t(
                      'order.tracking.modal_add_tracking_number.have_a_problem'
                    )}
                  </span>
                  <button
                    type="button"
                    className="font-semibold text-green-900"
                    onClick={() => {
                      track('OpenHelpChat', {
                        from: 'ModalOrderEditTracking',
                      });
                      openCrispChat();
                    }}
                  >
                    {t('contact_us')}
                  </button>
                </div>
                <div className="flex space-x-6">
                  <Button
                    type="button"
                    appearance="outline"
                    onClick={() => goToTracking()}
                  >
                    {t('previous')}
                  </Button>

                  {isGroupOrderParcelPending ? (
                    <div className="flex items-center justify-center space-x-2 p-2 text-sm">
                      <Loader className="size-4" />
                      <span className="text-gray-700">
                        {t(
                          'order.tracking.modal_edit_tracking_number.changes_in_progress'
                        )}
                      </span>
                    </div>
                  ) : (
                    <Button
                      type="button"
                      onClick={() => groupOrderParcel()}
                      disabled={isGroupOrderParcelPending}
                    >
                      {t(
                        'order.tracking.modal_edit_tracking_number.group_orders'
                      )}
                    </Button>
                  )}
                </div>
              </ModalFooter>
            </>
          ) : null}
          {step === EditStep.GROUP_CHOOSE ? (
            <>
              <div className="flex-auto overflow-y-auto p-6 md:p-10">
                <ModalHeader>
                  <ModalTitle>
                    {t(
                      'order.tracking.modal_edit_tracking_number.confirm_edit_tracking_number_and_grouping'
                    )}
                  </ModalTitle>
                </ModalHeader>
                <div className="space-y-10">
                  <Alert
                    appearance="default"
                    size="small"
                    icon="information"
                    title={t(
                      'order.tracking.modal_add_tracking_number.another_order_same_customer_same_tracking'
                    )}
                  >
                    <p className="text-sm text-gray-700">
                      {t(
                        'order.tracking.modal_add_tracking_number.orders_grouped_together_same_parcel'
                      )}
                    </p>
                  </Alert>

                  <div className="space-y-2">
                    <p className="text-sm text-gray-900">
                      <Trans
                        t={t}
                        i18nKey="order.tracking.x_order_of"
                        values={{
                          customer:
                            destinationOrdersByTrackingData?.[0]?.recipient
                              ?.fullName,
                          count: destinationOrdersByTrackingData?.length,
                        }}
                        components={{ b: <b className="text-gray-900" /> }}
                      />
                    </p>
                    <div className="divide-y divide-gray-300 rounded border border-gray-300">
                      <OrderDetails
                        order={{
                          id: order?.id,
                          parcels: [
                            {
                              trackingCourier: {
                                logoUrl: selectedCourier?.logoUrl ?? '',
                                name: selectedCourier?.name ?? '',
                              },
                              trackingNumber:
                                form.getValues('trackingNumber') ?? '',
                            },
                          ],
                        }}
                        orderIdStyle="success"
                      />
                    </div>
                    <div className="flex justify-end">
                      <div className="-my-6 mr-4 inline-flex rounded-full border border-[#C6E7D5] bg-[#ECF7F1] p-3">
                        <Courier className="text-green-600" />
                      </div>
                    </div>
                    <ul className="divide-y divide-gray-300 rounded border border-gray-300">
                      {destinationOrdersByTrackingData?.map((order) => (
                        <li key={order.id}>
                          <OrderDetails
                            order={{
                              id: order.id,
                              parcels: [
                                {
                                  trackingCourier: {
                                    logoUrl:
                                      order.parcels?.[0]?.trackingCourier
                                        ?.logoUrl ?? '',
                                    name:
                                      order.parcels?.[0]?.trackingCourier
                                        ?.name ?? '',
                                  },
                                  trackingNumber:
                                    order.parcels?.[0].trackingNumber ?? '',
                                },
                              ],
                            }}
                            showTrackingLink
                          />
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="space-y-2">
                    <p className="text-sm text-gray-900">
                      <Trans
                        t={t}
                        i18nKey="order.tracking.x_other_order_of_same_parcel"
                        values={{
                          customer:
                            destinationOrdersByTrackingData?.[0]?.recipient
                              ?.fullName,
                          count: destinationOrdersByTrackingData?.length,
                        }}
                        components={{ b: <b className="text-gray-900" /> }}
                      />
                    </p>
                    <ul className="space-y-2">
                      {fields.map((field, index) => (
                        <li key={field.id}>
                          <FormField
                            control={form.control}
                            name={`orders.${index}.selected`}
                            render={({ field: f }) => (
                              <OrderDetailsCheckbox
                                order={field.order}
                                {...f}
                                value=""
                                checked={f.value}
                                onCheckedChange={f.onChange}
                              />
                            )}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <ModalFooter className="justify-between">
                <div className="flex items-center space-x-1 text-sm">
                  <span className="text-gray-900">
                    {t(
                      'order.tracking.modal_add_tracking_number.have_a_problem'
                    )}
                  </span>
                  <button
                    type="button"
                    className="font-semibold text-green-900"
                    onClick={() => {
                      track('OpenHelpChat', {
                        from: 'ModalOrderEditTracking',
                      });
                      openCrispChat();
                    }}
                  >
                    {t('contact_us')}
                  </button>
                </div>
                <div className="flex space-x-6">
                  <Button
                    type="button"
                    appearance="outline"
                    onClick={() => goToTracking()}
                  >
                    {t('previous')}
                  </Button>

                  <Button
                    type="button"
                    onClick={() => setStep(EditStep.GROUP_CHOOSE_CONFIRM)}
                  >
                    {t('order.tracking.modal_edit_tracking_number.next')}
                  </Button>
                </div>
              </ModalFooter>
            </>
          ) : null}
          {step === EditStep.GROUP_CHOOSE_CONFIRM ? (
            <>
              <div className="flex-auto overflow-y-auto p-6 md:p-10">
                <ModalHeader>
                  <ModalTitle>
                    {t(
                      'order.tracking.modal_edit_tracking_number.confirm_edit_tracking_number_and_grouping'
                    )}
                  </ModalTitle>
                </ModalHeader>
                <div className="space-y-10">
                  <Alert
                    appearance="default"
                    size="small"
                    icon="information"
                    title={t(
                      'order.tracking.modal_add_tracking_number.another_order_same_customer_same_tracking'
                    )}
                  >
                    <p className="text-sm text-gray-700">
                      {t(
                        'order.tracking.modal_add_tracking_number.orders_grouped_together_same_parcel'
                      )}
                    </p>
                  </Alert>

                  <div className="space-y-2">
                    <p className="text-sm text-gray-900">
                      <Trans
                        t={t}
                        i18nKey="order.tracking.x_order_of"
                        values={{
                          customer:
                            destinationOrdersByTrackingData?.[0]?.recipient
                              ?.fullName,
                          count: destinationOrdersByTrackingData?.length,
                        }}
                        components={{ b: <b className="text-gray-900" /> }}
                      />
                    </p>
                    <div className="divide-y divide-gray-300 rounded border border-gray-300">
                      <OrderDetails
                        order={{
                          id: order?.id,
                          parcels: [
                            {
                              trackingCourier: {
                                logoUrl: selectedCourier?.logoUrl ?? '',
                                name: selectedCourier?.name ?? '',
                              },
                              trackingNumber:
                                form.getValues('trackingNumber') ?? '',
                            },
                          ],
                        }}
                        orderIdStyle="success"
                      />
                      {form
                        .getValues('orders')
                        .filter((order) => order.selected)
                        .map((order) => (
                          <OrderDetails
                            key={order.order.id}
                            order={{
                              id: order.order?.id,
                              parcels: [
                                {
                                  trackingCourier: {
                                    logoUrl: selectedCourier?.logoUrl ?? '',
                                    name: selectedCourier?.name ?? '',
                                  },
                                  trackingNumber:
                                    form.getValues('trackingNumber') ?? '',
                                },
                              ],
                            }}
                            orderIdStyle="success"
                          />
                        ))}
                    </div>
                    <div className="flex justify-end">
                      <div className="-my-6 mr-4 inline-flex rounded-full border border-[#C6E7D5] bg-[#ECF7F1] p-3">
                        <Courier className="text-green-600" />
                      </div>
                    </div>
                    <ul className="divide-y divide-gray-300 rounded border border-gray-300">
                      {destinationOrdersByTrackingData?.map((order) => (
                        <li key={order.id}>
                          <OrderDetails
                            order={{
                              id: order.id,
                              parcels: [
                                {
                                  trackingCourier: {
                                    logoUrl:
                                      order.parcels?.[0]?.trackingCourier
                                        ?.logoUrl ?? '',
                                    name:
                                      order.parcels?.[0]?.trackingCourier
                                        ?.name ?? '',
                                  },
                                  trackingNumber:
                                    order.parcels?.[0].trackingNumber ?? '',
                                },
                              ],
                            }}
                            showTrackingLink
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <ModalFooter className="justify-between">
                <div className="flex items-center space-x-1 text-sm">
                  <span className="text-gray-900">
                    {t(
                      'order.tracking.modal_add_tracking_number.have_a_problem'
                    )}
                  </span>
                  <button
                    type="button"
                    className="font-semibold text-green-900"
                    onClick={() => {
                      track('OpenHelpChat', {
                        from: 'ModalOrderEditTracking',
                      });
                      openCrispChat();
                    }}
                  >
                    {t('contact_us')}
                  </button>
                </div>
                <div className="flex space-x-6">
                  <Button
                    type="button"
                    appearance="outline"
                    onClick={() => setStep(EditStep.GROUP_CHOOSE)}
                  >
                    {t('previous')}
                  </Button>

                  {isGroupOrderParcelPending ? (
                    <div className="flex items-center justify-center space-x-2 p-2 text-sm">
                      <Loader className="size-4" />
                      <span className="text-gray-700">
                        {t(
                          'order.tracking.modal_edit_tracking_number.changes_in_progress'
                        )}
                      </span>
                    </div>
                  ) : (
                    <Button
                      type="button"
                      onClick={() => groupOrderParcel()}
                      disabled={isGroupOrderParcelPending}
                    >
                      {t(
                        'order.tracking.modal_edit_tracking_number.group_orders'
                      )}
                    </Button>
                  )}
                </div>
              </ModalFooter>
            </>
          ) : null}
          {step === EditStep.SWAP ? (
            <>
              <div className="flex-auto overflow-y-auto p-6 md:p-10">
                <ModalHeader>
                  <ModalTitle>
                    {t(
                      'order.tracking.modal_edit_tracking_number.confirm_tracking_reversal'
                    )}
                  </ModalTitle>
                </ModalHeader>
                <div className="space-y-10">
                  <p className="text-sm text-gray-700">
                    {t(
                      'order.tracking.modal_edit_tracking_number.tracking_already_exists_can_only_be_reversed'
                    )}
                    <br />
                    {t(
                      'order.tracking.modal_edit_tracking_number.customer_notified_of_change_after_confirmation'
                    )}
                  </p>

                  {swapOrderParcelError ? (
                    <Alert
                      appearance="error"
                      size="small"
                      icon="alert"
                      title={t(
                        'order.tracking.modal_edit_tracking_number.tracking_cannot_be_changed'
                      )}
                    >
                      <p className="text-sm text-gray-700">
                        {t(
                          'order.tracking.modal_edit_tracking_number.due_to_incompatibility'
                        )}
                      </p>
                    </Alert>
                  ) : null}

                  <div className="space-y-2">
                    <div className="divide-y divide-gray-300 rounded border border-gray-300">
                      {originOrdersByTrackingData?.map((order) => (
                        <OrderDetailsSwap
                          key={order.id}
                          newTrackingNumber={
                            destinationOrdersByTrackingData?.[0]?.parcels?.[0]
                              ?.trackingNumber ?? ''
                          }
                          order={{
                            id: order?.id,
                            recipient: order?.recipient,
                            parcels: [
                              {
                                trackingCourier: {
                                  logoUrl:
                                    order?.parcels?.[0]?.trackingCourier
                                      ?.logoUrl ?? '',
                                  name:
                                    order?.parcels?.[0]?.trackingCourier
                                      ?.name ?? '',
                                },
                                trackingNumber:
                                  order?.parcels?.[0]?.trackingNumber ?? '',
                              },
                            ],
                          }}
                        />
                      ))}
                    </div>
                    <div className="flex justify-end">
                      <div className="-my-6 mr-4 inline-flex rounded-full border border-[#C6E7D5] bg-[#ECF7F1] p-3">
                        <Exchange className="text-green-600" />
                      </div>
                    </div>
                    <ul className="divide-y divide-gray-300 rounded border border-gray-300">
                      {destinationOrdersByTrackingData?.map((order) => (
                        <li key={order.id}>
                          <OrderDetailsSwap
                            newTrackingNumber={
                              originOrdersByTrackingData?.[0]?.parcels?.[0]
                                ?.trackingNumber ?? ''
                            }
                            order={{
                              id: order.id,
                              recipient: order.recipient,
                              parcels: [
                                {
                                  trackingCourier: {
                                    logoUrl:
                                      order.parcels?.[0]?.trackingCourier
                                        ?.logoUrl ?? '',
                                    name:
                                      order.parcels?.[0]?.trackingCourier
                                        ?.name ?? '',
                                  },
                                  trackingNumber:
                                    order.parcels?.[0].trackingNumber ?? '',
                                },
                              ],
                            }}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <ModalFooter className="justify-between">
                <div className="flex items-center space-x-1 text-sm">
                  <span className="text-gray-900">
                    {t(
                      'order.tracking.modal_add_tracking_number.have_a_problem'
                    )}
                  </span>
                  <button
                    type="button"
                    className="font-semibold text-green-900"
                    onClick={() => {
                      track('OpenHelpChat', {
                        from: 'ModalOrderEditTracking',
                      });
                      openCrispChat();
                    }}
                  >
                    {t('contact_us')}
                  </button>
                </div>
                <div className="flex space-x-6">
                  <Button
                    type="button"
                    appearance="outline"
                    onClick={() => goToTracking()}
                  >
                    {t('previous')}
                  </Button>

                  {isSwapOrderParcelPending ? (
                    <div className="flex items-center justify-center space-x-2 p-2 text-sm">
                      <Loader className="size-4" />
                      <span className="text-gray-700">
                        {t(
                          'order.tracking.modal_edit_tracking_number.changes_in_progress'
                        )}
                      </span>
                    </div>
                  ) : (
                    <Button
                      type="button"
                      onClick={() => swapOrderParcel()}
                      disabled={isSwapOrderParcelPending}
                    >
                      {t(
                        'order.tracking.modal_edit_tracking_number.reverse_tracking_numbers'
                      )}
                    </Button>
                  )}
                </div>
              </ModalFooter>
            </>
          ) : null}
        </form>
      </Form>
    </div>
  );
};
