import {
  Address,
  getCountryFromCountryCode,
  getCountryFromCountryName,
} from '@appchoose/address';
import { SearchableSelectOption } from '@appchoose/searchable-select';
import { Address as ShopifyAddress } from '@shopify/address';
import { matchSorter } from 'match-sorter';

import { OrderQuery } from '../types/generated-new';

/**
 * Récupère le pays par défaut à utiliser.
 * Si le pays primaire est renseigné, on utilise celui-ci.
 * Sinon, si le pays d'expédition est renseigné, on utilise celui-ci.
 * Sinon on utilise France.
 * @param primaryCountryName Nom du pays primaire
 * @param shippingCountryCode Code du pays d'expédition
  @returns {Object} Le pays par défaut
 */
export const getDefaultOnboardingCountry = (
  primaryCountryName: string | undefined,
  shippingCountryCode: string
) => {
  const defaultCountry = getCountryFromCountryName('France');
  if (primaryCountryName) {
    const primaryCountry = getCountryFromCountryName(primaryCountryName);
    if (primaryCountry) {
      return primaryCountry;
    }
  }

  if (shippingCountryCode) {
    const shippingCountry = getCountryFromCountryCode(shippingCountryCode);
    if (shippingCountry) {
      return shippingCountry;
    }
  }

  return defaultCountry;
};

/**
 * Récupère le pays par défaut à utiliser.
 * Si le pays de facturation est renseignée, on utilise celui-ci.
 * Sinon on utilise France.
 * @param billingCountryName Nom du pays de facturation
  @returns {Object} Le pays par défaut
 */
export const getDefaultFiscalCountry = (
  billingCountryName: string | undefined
) => {
  const defaultCountry = getCountryFromCountryName('France');
  if (billingCountryName) {
    const billingCountry = getCountryFromCountryName(billingCountryName);
    if (billingCountry) {
      return billingCountry;
    }
  }

  return defaultCountry;
};

export const searchCountry = (
  term: string,
  options: SearchableSelectOption[],
  emptySearchResult: SearchableSelectOption[]
) => {
  return term.trim() === ''
    ? emptySearchResult
    : matchSorter(options, term, {
        keys: [
          (item: SearchableSelectOption) => `${item.label.replace(/-/g, ' ')}`,
        ],
      });
};

export const recipientToAddress = (
  recipient:
    | OrderQuery['order']['recipient']
    | NonNullable<OrderQuery['order']['recipient']['previous']>
): Address => {
  return {
    name: recipient.fullName,
    street: recipient.address.street,
    street2: recipient.address.streetAdditional ?? undefined,
    city: recipient.address.city,
    province: recipient.address.state ?? undefined,
    bp: recipient.address.zipCode,
    country: recipient.address.country,
    countryCode: recipient.address.countryCode,
  };
};

export const recipientToShopifyAddress = (
  recipient:
    | OrderQuery['order']['recipient']
    | NonNullable<OrderQuery['order']['recipient']['previous']>
): ShopifyAddress => {
  return {
    firstName: recipient.firstName,
    lastName: recipient.lastName,
    address1: recipient.address.street,
    address2: recipient.address.streetAdditional
      ? recipient.address.streetAdditional
      : '',
    city: recipient.address.city,
    province: recipient.address.state ?? undefined,
    zip: recipient.address.zipCode,
    country: recipient.address.countryCode ?? 'FR',
  };
};
