import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Button from '@appchoose/button';
import Illustration from '@appchoose/illustration';
import { useAuth0 } from '@auth0/auth0-react';
import i18n from 'i18next';

import { DesktopClosedIllutration } from '../../components/icons/desktop-closed';
import { DesktopWaitIllutration } from '../../components/icons/desktop-wait';
import { brandState } from '../../stores/brand';
import { activeSaleState, shortActiveSaleIdState } from '../../stores/sales';
import { SaleStatus } from '../../types/generated';
import { formatFullDate } from '../../utils/date';
import { BillingLockedErrors } from './billing-locked-errors';
import { BillingPayments } from './billing-payments';
import { InvoiceLockedContent } from './invoice-locked-content';
import { useBillingState } from './use-billing-state';

export const InvoiceLocked: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const brand = useRecoilValue(brandState);
  const { data: billingState } = useBillingState();
  const activeSale = useRecoilValue(activeSaleState);
  const shortActiveSaleId = useRecoilValue(shortActiveSaleIdState);

  const { user } = useAuth0();

  const isInternalUser = user?.['https://appchoose.io/claims/isInternalUser'];

  if (billingState?.saleStatus === SaleStatus.Upcoming) {
    return (
      <InvoiceLockedContent>
        <DesktopWaitIllutration
          title={t('invoice.invoice_locked.billing_blocked')}
          className="mb-2"
        />
        <h3 className="mb-1 text-center text-2xl font-bold text-gray-900">
          {t('invoice.invoice_locked.sale_has_not_started')}
        </h3>
        <p className="text-sm text-gray-500">
          {t('invoice.invoice_locked.invoice_available_after_sale')}
        </p>
      </InvoiceLockedContent>
    );
  }

  if (billingState?.saleStatus === SaleStatus.Ongoing) {
    return (
      <InvoiceLockedContent>
        <Illustration illustration="desktopOn" className="mb-2" />
        <h3 className="mb-1 text-center text-2xl font-bold text-gray-900">
          {t('invoice.invoice_locked.sale_is_running')}
        </h3>
        <p className="max-w-[34rem] text-center text-sm text-gray-500">
          {t(
            'invoice.invoice_locked.sale_ongoing_billing_available_fulfilled_requirements'
          )}
        </p>
      </InvoiceLockedContent>
    );
  }

  if (
    billingState?.editableAt &&
    billingState.saleStatus === SaleStatus.Ended &&
    billingState.allClaimsFulfilled &&
    billingState.allOrdersDelivered &&
    billingState.allReturnsValidated
  ) {
    return (
      <InvoiceLockedContent>
        <Illustration illustration="desktopChecked" className="mb-2" />
        <h3 className="mb-1 text-center text-2xl font-bold text-gray-900">
          {t('invoice.invoice_locked.done_all_the_work')}
        </h3>
        <p className="max-w-[34rem] whitespace-pre-line pb-4 text-center text-sm text-gray-500">
          {t(
            'invoice.invoice_locked.invoice_available_after_withdrawal_period'
          )}
        </p>
        <div className="rounded border border-gray-100 bg-[#fbfbfb] px-6 py-4 text-center text-sm text-gray-900">
          <div className="pb-1 text-xs font-semibold uppercase tracking-wider text-gray-500">
            {t('invoice.invoice_locked.unlocking_estimate')}
          </div>
          <span className="font-bold">
            {formatFullDate(
              new Date(billingState.editableAt),
              i18n.language === 'fr' ? 'fr' : 'en',
              brand?.timezone ?? undefined
            )}
          </span>
        </div>
      </InvoiceLockedContent>
    );
  }

  return (
    <div className="flex w-full flex-col items-center space-y-16">
      <InvoiceLockedContent>
        <DesktopClosedIllutration
          title={t('invoice.invoice_locked.billing_blocked')}
          className="mb-2"
        />
        <h3 className="mb-1 text-center text-2xl font-bold text-gray-900">
          {t('invoice.invoice_locked.sale_is_over_but')}
        </h3>
        <p className="mb-6 max-w-[34rem] whitespace-pre-line pb-4 text-center text-sm text-gray-500">
          {t(
            'invoice.invoice_locked.sale_ended_billing_available_fulfilled_requirements'
          )}
        </p>
        {activeSale ? (
          <Button
            type="button"
            appearance="primary"
            onClick={() =>
              navigate(`/${brand?.id}/orders?sid=${shortActiveSaleId}`, {
                replace: true,
              })
            }
          >
            {t('invoice.invoice_locked.show_orders')}
          </Button>
        ) : null}
      </InvoiceLockedContent>
      <BillingPayments />
      {isInternalUser && <BillingLockedErrors />}
    </div>
  );
};
