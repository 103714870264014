import React, { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Input from '@appchoose/input';
import { SearchableSelectOption } from '@appchoose/searchable-select';
import { isSupportedCountry, isValidPhoneNumber } from 'libphonenumber-js';
import Mailchecker from 'mailchecker';

import { PhoneInput } from '../phone-input/phone-input';

type ContactFormFieldsProps = {
  autoFocus?: boolean;
  phoneCountry: SearchableSelectOption;
  setPhoneCountry: (newCountry: SearchableSelectOption) => void;
};

export type BrandContactFormData = {
  phone: string;
  email: string;
};

export const ContactFormFields: React.FC<ContactFormFieldsProps> = ({
  autoFocus,
  phoneCountry,
  setPhoneCountry,
}) => {
  const { t } = useTranslation();
  const form = useFormContext<BrandContactFormData>();

  useUpdateEffect(() => {
    form.setValue('phone', '', {
      shouldValidate: true,
    });
  }, [phoneCountry]);

  return (
    <>
      <FormField
        control={form.control}
        name="phone"
        rules={{
          required: true,
          maxLength: 40,
          validate: {
            validPhoneNumber: (value) => {
              // Toujours valide si le pays n'est pas supporté
              return isSupportedCountry(phoneCountry.value)
                ? isValidPhoneNumber(value, phoneCountry.value)
                : true;
            },
          },
        }}
        render={({ field, fieldState: { invalid } }) => (
          <FormItem className="mt-6">
            <FormLabel>
              {t('brand_info.contact_form_fields.phone.label')}
            </FormLabel>
            <FormControl>
              <PhoneInput
                autoFocus={autoFocus}
                phoneCountry={phoneCountry}
                setPhoneCountry={setPhoneCountry}
                {...field}
              />
            </FormControl>
            {!invalid ? (
              <span className="text-xs">
                {t('brand_info.contact_form_fields.phone.help')}
              </span>
            ) : null}
            <FormMessage match="required">
              {t(
                'brand_info.contact_form_fields.phone.validation_errors.required'
              )}
            </FormMessage>
            <FormMessage match="maxLength">
              {t(
                'brand_info.contact_form_fields.phone.validation_errors.maxLength',
                {
                  maxLength: '40',
                }
              )}
            </FormMessage>
            <FormMessage match="validPhoneNumber">
              {t(
                'brand_info.contact_form_fields.phone.validation_errors.validPhoneNumber'
              )}
            </FormMessage>
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="email"
        rules={{
          required: true,
          maxLength: 50,
          validate: {
            email: (value) => Mailchecker.isValid(value),
          },
        }}
        render={({ field, fieldState: { invalid } }) => (
          <FormItem className="mt-6">
            <FormLabel>
              {t('brand_info.contact_form_fields.email.label')}
            </FormLabel>
            <FormControl>
              <Input
                type="email"
                placeholder="example@gmail.com"
                autoComplete="email"
                inputMode="email"
                {...field}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const { value } = event.target;
                  event.target.value = value.trim();
                  field.onChange(event);
                }}
              />
            </FormControl>
            {!invalid ? (
              <span className="text-xs">
                {t('brand_info.contact_form_fields.email.help')}
              </span>
            ) : null}
            <FormMessage match="required">
              {t(
                'brand_info.contact_form_fields.email.validation_errors.required'
              )}
            </FormMessage>
            <FormMessage match="maxLength">
              {t(
                'brand_info.contact_form_fields.email.validation_errors.maxLength',
                {
                  maxLength: '50',
                }
              )}
            </FormMessage>
            <FormMessage match="email">
              {t(
                'brand_info.contact_form_fields.email.validation_errors.email'
              )}
            </FormMessage>
          </FormItem>
        )}
      />
    </>
  );
};

ContactFormFields.displayName = 'ContactFormFields';
