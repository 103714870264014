import React, { ChangeEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import { useRecoilState } from 'recoil';

import { Address } from '@appchoose/address';
import AddressFormFields from '@appchoose/address-form-fields';
import Button from '@appchoose/button';
import { Form } from '@appchoose/form';
import SearchableSelect, {
  SearchableSelectOption,
} from '@appchoose/searchable-select';

import { brandState } from '../../stores/brand';
import { StoreRegion, useUpdateSellerMutation } from '../../types/generated';
import {
  getDefaultOnboardingCountry,
  searchCountry,
} from '../../utils/address';
import { getCountryOptions } from '../../utils/address-options';
import { removeAllSpaces } from '../../utils/string';
import { isInUE } from '../../utils/utils';
import { LegalInfoFormFields } from '../brand-info/legal-info-form-fields';
import { OnboardingStepBillingInfoForm } from '../onboarding/onboarding-step-billing-info';
import { TaxFormFields } from '../tax-form-fields/tax-form-fields';

import '@appchoose/searchable-select/dist/style.css';

export type BillingHandlerProps = {
  goToNextStep: () => void;
  goToNextSubStepOrStep: () => void;
};

const FiscalCountryNoResult: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="px-4 py-2 text-sm text-gray-500">
      {t('address.fields.country.no_results')}
    </div>
  );
};

export const BillingHandler: React.FC<BillingHandlerProps> = ({
  goToNextSubStepOrStep,
}: BillingHandlerProps) => {
  const [brand, setBrand] = useRecoilState(brandState);
  const { i18n, t } = useTranslation();

  const defaultFiscalCountry = getDefaultOnboardingCountry(
    brand?.billing_address?.country,
    brand?.shipping_country ?? ''
  );
  const fiscalCountryOptions = getCountryOptions();

  const { mutateAsync: updateSellerMutation } = useUpdateSellerMutation();

  const form = useForm<OnboardingStepBillingInfoForm>({
    mode: 'onTouched',
    defaultValues: {
      fiscalCountryCode: defaultFiscalCountry?.code || '',
      legalEntityName: brand?.billing_address?.name ?? '',
      taxId: brand?.tax_id ?? undefined,
      siret: removeAllSpaces(brand?.siret ?? ''),
      intracommunityVat: brand?.intracommunity_vat ?? undefined,
      isNotSubjectVat:
        brand?.is_autoentrepreneur !== null
          ? brand?.is_autoentrepreneur
          : false,
      street: brand?.billing_address?.street ?? '',
      street2: brand?.billing_address?.street2 ?? '',
      bp: brand?.billing_address?.bp ?? '',
      city: brand?.billing_address?.city ?? '',
      province: brand?.billing_address?.province ?? '',
      country: brand?.billing_address?.country ?? defaultFiscalCountry.fr ?? '',
      countryCode:
        brand?.billing_address?.countryCode ?? defaultFiscalCountry?.code ?? '',
    },
  });
  const [fiscalCountry, setFiscalCountry] = useState<
    SearchableSelectOption | undefined
  >(fiscalCountryOptions.find((o) => o.value === defaultFiscalCountry?.code));

  const onSubmit = (data: OnboardingStepBillingInfoForm) => {
    if (!brand) return;
    const billingAddress: Address = {
      name: data.legalEntityName.trim(),
      street: data.street.trim(),
      street2: data.street2?.trim(),
      bp: data.bp.trim(),
      province: data.province?.trim(),
      city: data.city.trim(),
      country: data.country,
      countryCode: data.countryCode,
    };
    setBrand({
      ...brand,
      siret: data.siret ?? '',
      is_autoentrepreneur: data.isNotSubjectVat,
      tax_id: data.taxId ?? '',
      intracommunity_vat: data.intracommunityVat,
      billing_address: billingAddress,
    });
    updateSellerMutation({
      updateSeller: {
        siret: data.siret,
        is_autoentrepreneur: data.isNotSubjectVat,
        tax_id: data.taxId,
        intracommunity_vat: data.intracommunityVat,
        address: billingAddress,
      },
    });
    goToNextSubStepOrStep();
  };

  useUpdateEffect(() => {
    form.setValue('fiscalCountryCode', fiscalCountry?.value);
    form.setValue('country', fiscalCountry?.label ?? '', {
      shouldValidate: true,
    });
    form.setValue('countryCode', fiscalCountry?.value ?? '');
  }, [fiscalCountry]);

  // Rerender on 'fiscalCountryCode' change
  form.watch('fiscalCountryCode');

  const registerFiscalCountryCode = {
    ...form.register('fiscalCountryCode'),
  };

  return (
    <>
      <div className="mt-8 sm:mt-20">
        <h2 className="mb-8 text-2xl font-bold sm:mb-10 sm:text-3.5xl">
          {t('onboarding.billing.sub_steps.1.title')}
        </h2>
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="space-y-14">
            <section>
              <div className="pb-8 text-2xl font-bold">
                {t('onboarding.billing.sub_steps.1.subtitle_country')}
              </div>
              <SearchableSelect
                defaultValue={fiscalCountry}
                placeholder={t('address.fields.country.placeholder')}
                noResults={FiscalCountryNoResult}
                emptyValue={t('address.fields.country.empty_field')}
                search={(term: string) =>
                  searchCountry(
                    term,
                    fiscalCountryOptions,
                    fiscalCountryOptions
                  )
                }
                onSelect={(selectedCountry: SearchableSelectOption) => {
                  setFiscalCountry(selectedCountry);
                }}
              />
              <input
                {...registerFiscalCountryCode}
                type="text"
                autoComplete="country"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setFiscalCountry(
                    fiscalCountryOptions.find((o) => o.value === e.target.value)
                  );
                  registerFiscalCountryCode.onChange(e);
                }}
                tabIndex={-1}
                className="sr-only"
              />
            </section>
            <section>
              <div className="pb-8 text-2xl font-bold">
                {t('onboarding.billing.sub_steps.1.subtitle_legal_info')}
              </div>
              <LegalInfoFormFields
                hasSiret={
                  form.getValues('fiscalCountryCode') === 'FR' &&
                  brand?.store === StoreRegion.Fr
                }
                hasTaxId={false}
              />
            </section>
            {isInUE(form.getValues('fiscalCountryCode')) &&
            brand?.store === StoreRegion.Fr ? (
              <section>
                <div className="pb-8 text-2xl font-bold">
                  {t('onboarding.billing.sub_steps.1.subtitle_tax_info')}
                </div>
                <TaxFormFields countryCode={fiscalCountry?.value ?? ''} />
              </section>
            ) : null}
            <section>
              <div className="pb-8 text-2xl font-bold">
                {t('onboarding.billing.sub_steps.1.subtitle_billing_address')}
              </div>
              <AddressFormFields
                googleMapsApiKey={
                  import.meta.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''
                }
                locale={i18n.language}
                showCompany={false}
                showName={false}
                showPhone={false}
                disabledCountry={true}
                countryRestrictions={form.getValues('fiscalCountryCode')}
                translations={{
                  suggestions: t('suggestions'),
                  fieldsNameValidationErrorsRequired: t(
                    'address.fields.name.validation_errors.required'
                  ),
                  fieldsNameValidationErrorsMaxLength: t(
                    'address.fields.name.validation_errors.maxLength',
                    {
                      maxLength: '200',
                    }
                  ),
                  fieldsFirstnameValidationErrorsRequired: t(
                    'address.fields.firstname.validation_errors.required'
                  ),
                  fieldsFirstnameValidationErrorsMaxLength: t(
                    'address.fields.firstname.validation_errors.maxLength',
                    {
                      maxLength: '200',
                    }
                  ),
                  fieldsLastnameValidationErrorsRequired: t(
                    'address.fields.lastname.validation_errors.required'
                  ),
                  fieldsLastnameValidationErrorsMaxLength: t(
                    'address.fields.lastname.validation_errors.maxLength',
                    {
                      maxLength: '200',
                    }
                  ),
                  fieldsPhoneValidationErrorsRequired: t(
                    'address.fields.phone.validation_errors.required'
                  ),
                  fieldsPhoneValidationErrorsMaxLength: t(
                    'address.fields.phone.validation_errors.maxLength',
                    {
                      maxLength: '200',
                    }
                  ),
                  fieldsStreetValidationErrorsRequired: t(
                    'address.fields.street.validation_errors.required'
                  ),
                  fieldsStreetValidationErrorsMaxLength: t(
                    'address.fields.street.validation_errors.maxLength',
                    {
                      maxLength: '200',
                    }
                  ),
                  fieldsStreet2ValidationErrorsMaxLength: t(
                    'address.fields.street2.validation_errors.maxLength',
                    {
                      maxLength: '200',
                    }
                  ),
                  fieldsBpValidationErrorsRequired: t(
                    'address.fields.bp.validation_errors.required'
                  ),
                  fieldsBpValidationErrorsMaxLength: t(
                    'address.fields.bp.validation_errors.maxLength',
                    {
                      maxLength: '200',
                    }
                  ),
                  fieldsCityValidationErrorsRequired: t(
                    'address.fields.city.validation_errors.required'
                  ),
                  fieldsCityValidationErrorsMaxLength: t(
                    'address.fields.city.validation_errors.maxLength',
                    {
                      maxLength: '200',
                    }
                  ),
                  fieldsProvinceValidationErrorsRequired: t(
                    'address.fields.province.validation_errors.required'
                  ),
                  fieldsProvinceValidationErrorsMaxLength: t(
                    'address.fields.province.validation_errors.maxLength',
                    {
                      maxLength: '200',
                    }
                  ),
                  fieldsCountryValidationErrorsRequired: t(
                    'address.fields.country.validation_errors.required'
                  ),
                  fieldsNameLabel: t('address.fields.name.label_return'),
                  fieldsNamePlaceholder: t('address.fields.name.placeholder'),
                  fieldsFirstnameLabel: t('address.fields.firstname.label'),
                  fieldsFirstnamePlaceholder: t(
                    'address.fields.firstname.placeholder'
                  ),
                  fieldsLastnameLabel: t('address.fields.lastname.label'),
                  fieldsLastnamePlaceholder: t(
                    'address.fields.lastname.placeholder'
                  ),
                  fieldsPhoneLabel: t('address.fields.phone.label'),
                  fieldsPhonePlaceholder: t('address.fields.phone.placeholder'),
                  fieldsStreetLabel: t('address.fields.street.label'),
                  fieldsStreetPlaceholder: t(
                    'address.fields.street.placeholder'
                  ),
                  fieldsStreet2Label: t('address.fields.street2.label'),
                  fieldsStreet2Placeholder: t(
                    'address.fields.street2.placeholder'
                  ),
                  fieldsBpLabel: t('address.fields.bp.label'),
                  fieldsBpPlaceholder: '',
                  fieldsCityLabel: t('address.fields.city.label'),
                  fieldsCityPlaceholder: '',
                  fieldsProvinceLabel: t('address.fields.province.label'),
                  fieldsProvincePlaceholder: '',
                  fieldsProvinceEmptyField: t(
                    'address.fields.province.empty_field'
                  ),
                  fieldsCountryLabel: t('address.fields.country.label'),
                  fieldsCountryPlaceholder: t(
                    'address.fields.country.placeholder'
                  ),
                  fieldsCountryPlaceholderSearch: t(
                    'address.fields.country.placeholder_search'
                  ),
                  fieldsCountryEmptyField: t(
                    'address.fields.country.empty_field'
                  ),
                  fieldsCountryNoResults: t(
                    'address.fields.country.no_results'
                  ),
                }}
              />
            </section>
          </div>
          <div className="mt-8 flex sm:mt-10">
            <Button type="submit" size="large">
              {t('continue')}
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
};

BillingHandler.displayName = 'BillingHandler';
