import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';
import { Modal, ModalContent, ModalTrigger } from '@appchoose/modal';
import { useAuth0 } from '@auth0/auth0-react';

import { OrderQuery } from '../../types/generated-new';
import { track } from '../../utils/analytics';
import { isScopeAuthorized } from '../../utils/auth';
import { openCrispChat } from '../../utils/crisp';
import { ModalConfirmRefund } from './modal-confirm-refund';
import { ModalConfirmReturn } from './modal-confirm-return';
import { ModalDigitalCodeAlreadySolved } from './modal-digital-code-already-solved';
import { ModalNewDigitalCode } from './modal-new-digital-code';

export type ClaimActionsProps = {
  claim: OrderQuery['order']['claims'][0];
  isFullDigital: OrderQuery['order']['isFullDigital'];
  orderId: OrderQuery['order']['id'];
  order?: OrderQuery['order'];
};

export const ClaimActions: React.FC<ClaimActionsProps> = ({
  claim,
  isFullDigital,
  orderId,
  order,
}: ClaimActionsProps) => {
  const { user } = useAuth0();
  const { t } = useTranslation();

  const [displayModalReturn, setDisplayModalReturn] = useState(false);
  const [displayModalRefund, setDisplayModalRefund] = useState(false);
  const [displayModalNewDigitalCode, setDisplayModalNewDigitalCode] =
    useState(false);
  const [
    displayModalDigitalCodeAlreadySolved,
    setDisplayModalDigitalCodeAlreadySolved,
  ] = useState(false);

  const openModalRefund = () => {
    track('OpenModalRefund', {});
  };

  const openModalReturn = () => {
    track('OpenModalProductReturn', {});
  };

  const openModalNewDigitalCode = () => {
    track('OpenModalNewDigitalCode', {});
  };

  const openModalDigitalCodeAlreadySolved = () => {
    track('OpenModalDigitalCodeAlreadySolved', {});
  };

  const onOpenChat = () => {
    track('OpenHelpChat', {
      from: 'ShippingComponent',
    });
    openCrispChat();
  };

  return (
    <div className="mt-4 flex flex-col border-t border-gray-300 pt-4">
      <h3 className="mb-2 text-sm font-semibold text-gray-900">
        {t('solution')}
      </h3>
      {!isFullDigital ? (
        <>
          <Modal open={displayModalReturn} onOpenChange={setDisplayModalReturn}>
            <ModalTrigger asChild>
              <Button
                onClick={openModalReturn}
                type="button"
                appearance="primary"
                className="justify-center"
                disabled={
                  !isScopeAuthorized(user, 'scope.brand.claims.write') ||
                  (claim.reshipActionErrorReasons?.length ?? 0) !== 0
                }
              >
                {t(
                  claim
                    ? 'order.actions_return_product'
                    : 'order.actions_return'
                )}
              </Button>
            </ModalTrigger>
            <ModalContent>
              <ModalConfirmReturn
                claim={claim}
                orderId={orderId}
                setIsOpen={setDisplayModalReturn}
              />
            </ModalContent>
          </Modal>

          <p className="mb-4 mt-2 text-center text-xs text-gray-500">
            {t('order.actions_fake')}
          </p>
          <Modal open={displayModalRefund} onOpenChange={setDisplayModalRefund}>
            <ModalTrigger asChild>
              <Button
                type="button"
                appearance="primary"
                className="justify-center"
                disabled={!isScopeAuthorized(user, 'scope.brand.claims.write')}
                onClick={openModalRefund}
              >
                {t(
                  claim
                    ? 'order.actions_refund_product'
                    : 'order.actions_refund'
                )}
              </Button>
            </ModalTrigger>
            <ModalContent>
              <ModalConfirmRefund
                claim={claim}
                orderId={orderId}
                order={order}
                setIsOpen={setDisplayModalRefund}
              />
            </ModalContent>
          </Modal>
        </>
      ) : (
        <div className="flex flex-col space-y-4">
          <Modal
            open={displayModalNewDigitalCode}
            onOpenChange={setDisplayModalNewDigitalCode}
          >
            <ModalTrigger asChild>
              <Button
                onClick={openModalNewDigitalCode}
                type="button"
                appearance="primary"
                className="justify-center"
                disabled={!isScopeAuthorized(user, 'scope.brand.claims.write')}
              >
                {t('order.actions_generate_new_digital_code')}
              </Button>
            </ModalTrigger>
            <ModalContent scrollable>
              <ModalNewDigitalCode
                claim={claim}
                orderId={order?.id}
                setIsOpen={setDisplayModalNewDigitalCode}
              />
            </ModalContent>
          </Modal>

          <Modal
            open={displayModalDigitalCodeAlreadySolved}
            onOpenChange={setDisplayModalDigitalCodeAlreadySolved}
          >
            <ModalTrigger asChild>
              <Button
                onClick={openModalDigitalCodeAlreadySolved}
                type="button"
                appearance="outline"
                className="justify-center"
                disabled={!isScopeAuthorized(user, 'scope.brand.claims.write')}
              >
                {t('order.actions_digital_code_already_solved')}
              </Button>
            </ModalTrigger>
            <ModalContent scrollable>
              <ModalDigitalCodeAlreadySolved
                claim={claim}
                orderId={order?.id}
                setIsOpen={setDisplayModalDigitalCodeAlreadySolved}
              />
            </ModalContent>
          </Modal>

          <Modal open={displayModalRefund} onOpenChange={setDisplayModalRefund}>
            <ModalTrigger asChild>
              <Button
                onClick={openModalRefund}
                type="button"
                appearance="outline"
                className="justify-center"
                disabled={!isScopeAuthorized(user, 'scope.brand.claims.write')}
              >
                {t(
                  claim
                    ? 'order.actions_refund_product'
                    : 'order.actions_refund'
                )}
              </Button>
            </ModalTrigger>
            <ModalContent>
              <ModalConfirmRefund
                claim={claim}
                order={order}
                orderId={order?.id}
                setIsOpen={setDisplayModalRefund}
              />
            </ModalContent>
          </Modal>
        </div>
      )}

      <button
        type="button"
        onClick={onOpenChat}
        className="mt-4 text-xs font-semibold text-green-900"
      >
        {t('order.actions_contact_us_via_chat')}
      </button>
    </div>
  );
};
