import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { activeSaleState } from '../../stores/sales';
import { formatPrice } from '../../utils/currency';
import { transformDateSimple } from '../../utils/date';
import { DashedLine } from './dashed-line';
import { useBilling } from './use-billing-stats';

export const BillingPayments: React.FC = () => {
  const { i18n, t } = useTranslation();
  const { data: billing } = useBilling();
  const activeSale = useRecoilValue(activeSaleState);

  if (!(billing?.payment_info?.amount_paid ?? 0)) return null;

  return (
    <section>
      <h3 className="mb-4 text-xl font-bold">{t('invoice.payments.title')}</h3>
      <div className="mb-6 overflow-hidden rounded-lg border border-gray-300">
        <ul className="py-2">
          <li className="flex justify-between space-x-2 px-6 py-2 text-base leading-5.5 text-gray-700 transition-colors duration-300 hover:bg-gray-50">
            <div className="shrink-0 space-x-1">
              <span className="font-semibold">
                {billing?.payment_info?.is_full_paid
                  ? t('invoice.payments.invoice_paid')
                  : t('invoice.payments.deposit')}
              </span>
              <span className="text-gray-500">
                (
                {transformDateSimple(
                  new Date(billing?.payment_info?.last_payment_at ?? ''),
                  i18n.language === 'fr' ? 'fr' : 'en',
                  true,
                  'Europe/Paris'
                )}
                )
              </span>
            </div>
            <div className="flex w-full items-end pb-1.5">
              <DashedLine />
            </div>
            <div className="shrink-0 font-semibold">
              {formatPrice(
                (billing?.payment_info?.amount_paid ?? 0) / 100,
                i18n.language === 'fr' ? 'fr' : 'en',
                activeSale?.currency?.toString()?.toUpperCase() ?? 'EUR'
              )}
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};
