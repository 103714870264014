import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  NavLink,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { atom, useRecoilState, useRecoilValue } from 'recoil';

import cn from '@appchoose/cn';
import Logo from '@appchoose/logo';
import { useAuth0 } from '@auth0/auth0-react';

import { ProtectedRouteWithScopes } from '../../components/protected-route-with-scopes/protected-route-with-scopes';
import { SalesSelector } from '../../components/sales-selector/sales-selector';
import { brandState } from '../../stores/brand';
import { activeSaleIdState } from '../../stores/sales';
import { useOrdersCountersQuery } from '../../types/generated-new';
import { BrandMatch } from '../../types/navigation';
import { track } from '../../utils/analytics';
import { isScopeAuthorized } from '../../utils/auth';
import { openCrispChat } from '../../utils/crisp';
import { AnalyticsScreen } from '../analytics-screen/analytics-screen';
import { BillingScreen } from '../billing-screen/billing-screen';
import { FAQScreen } from '../faq-screen/faq-screen';
import { HomeScreen } from '../home-screen/home-screen';
import { Orders } from '../orders-page/orders-page';
import { SettingsScreen } from '../settings-screen/settings-screen';
import { StockScreen } from '../stock-screen/stock-screen';
import { UpdatingInfoScreen } from '../updating-info-screen/updating-info-screen';
import { MobileSidebar } from './mobile-sidebar';
import { OnboardingSurvey } from './onboarding-survey';

import './dashboard-screen.scss';

export const isSidebarMenuOpenState = atom<boolean>({
  key: 'isSidebarMenuOpenState',
  default: false,
});

export const DashboardScreen: React.FC = () => {
  const { user } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  const { brandId = '' } = useParams<BrandMatch>();
  const { t } = useTranslation();

  const [isSidebarMenuOpen, setIsSidebarMenuOpen] = useRecoilState(
    isSidebarMenuOpenState
  );

  const brand = useRecoilValue(brandState);

  const activeSaleId = useRecoilValue(activeSaleIdState);
  const isInternalUser = user?.['https://appchoose.io/claims/isInternalUser'];

  const { data: ordersCountersData } = useOrdersCountersQuery(
    {
      sellerId: brandId,
      saleId: activeSaleId ?? '',
    },
    {
      enabled: !!brandId && !!activeSaleId,
      refetchOnWindowFocus: false,
    }
  );

  // /<id>/<path>
  const path = location.pathname.split('/')?.[2] || '';

  useEffect(() => {
    if (isSidebarMenuOpen) setIsSidebarMenuOpen(false);

    if (isInternalUser) {
      if (path === 'onboarding') {
        navigate(`/${brandId}/home`, { replace: true });
      }
    } else {
      if (brand?.accept_contract) {
        if (brand && !brand.need_refresh_info) {
          if (path === 'updating-info') {
            navigate(`/${brandId}/home`, { replace: true });
          }
        } else {
          if (path !== 'updating-info') {
            navigate(`/${brandId}/updating-info/0`, { replace: true });
          }
        }

        if (path === 'onboarding') {
          navigate(`/${brandId}/home`, { replace: true });
        }
      } else {
        if (path !== 'onboarding') {
          if (user) {
            if (user['https://appchoose.io/claims/needOnboarding'] === true) {
              navigate(`/${brandId}/onboarding/1/2`, { replace: true });
            } else {
              navigate(`/${brandId}/onboarding/2`, { replace: true });
            }
          } else {
            navigate(`/${brandId}/onboarding/0`, { replace: true });
          }
        }
      }
    }
  }, [location]);

  const displayHelp = () => {
    track('OpenHelpChat', {
      from: 'DashboardScreen',
    });
    openCrispChat();
  };

  const actionRequiredCounter =
    ordersCountersData?.ordersCounters?.tagging?.brand?.actionRequired;

  return (
    <Routes>
      <Route path="updating-info/:step?/*" element={<UpdatingInfoScreen />} />
      <Route
        path="*"
        element={
          <div className="dashboard flex h-screen overflow-hidden">
            <section
              className={cn(
                `sidebar flex min-h-0 w-[192px] flex-col bg-gray-100`,
                {
                  opened: isSidebarMenuOpen,
                }
              )}
            >
              <header className="relative mb-8 flex flex-col items-start min-[960px]:mb-20">
                <div className="mb-4 mt-14 space-y-4 px-6 min-[960px]:mt-0">
                  <NavLink
                    to={`/${brandId}/home${location.search}`}
                    className="hidden rounded-lg focus-visible:ring-2 focus-visible:ring-gray-900/30 min-[960px]:inline-block"
                  >
                    {import.meta.env.REACT_APP_STAGE === 'production' ? (
                      <Logo role="img" aria-label="Logo Choose" />
                    ) : (
                      <Logo
                        appearance="white"
                        role="img"
                        aria-label="Logo Choose"
                      />
                    )}
                  </NavLink>
                  <p className="hidden font-semibold leading-5.5 text-gray-900 min-[960px]:block">
                    {brand?.name}
                  </p>
                </div>
                <NavLink
                  to={`/${brandId}/settings${location.search}`}
                  className="border-gray-500 px-4 text-xs hover:font-bold aria-[current=page]:border-l-2 aria-[current=page]:px-3.5 aria-[current=page]:font-bold aria-[current=page]:text-gray-900 min-[960px]:px-6 min-[960px]:aria-[current=page]:px-5.5"
                >
                  {t('sidebar.settings')}
                </NavLink>
              </header>
              <div className="flex flex-1 flex-col overflow-y-auto">
                <div className="flex-1 space-y-8 min-[960px]:space-y-4">
                  <NavLink
                    to={`/${brandId}/home${location.search}`}
                    className="flex flex-row items-center pl-4 pr-6 text-2xl text-gray-700 hover:font-bold hover:text-gray-900 aria-[current=page]:border-l-2 aria-[current=page]:border-gray-500 aria-[current=page]:pl-3.5 aria-[current=page]:font-bold aria-[current=page]:text-gray-900 min-[960px]:pl-6 min-[960px]:text-base min-[960px]:aria-[current=page]:pl-5.5"
                  >
                    {t('sidebar.home')}
                  </NavLink>
                  <NavLink
                    to={`/${brandId}/orders${location.search}`}
                    className="flex flex-row items-center space-x-3 pl-4 pr-6 text-2xl text-gray-700 hover:font-bold hover:text-gray-900 aria-[current=page]:border-l-2 aria-[current=page]:border-gray-500 aria-[current=page]:pl-3.5 aria-[current=page]:font-bold aria-[current=page]:text-gray-900 min-[960px]:justify-between min-[960px]:pl-6 min-[960px]:text-base min-[960px]:aria-[current=page]:pl-5.5"
                  >
                    <span>{t('sidebar.orders')}</span>
                    {actionRequiredCounter && actionRequiredCounter > 0 ? (
                      <p className="block rounded bg-green-900 px-1.25 py-1 text-xs font-semibold text-white">
                        {actionRequiredCounter}
                      </p>
                    ) : null}
                  </NavLink>
                  <NavLink
                    to={`/${brandId}/stock${location.search}`}
                    className="flex flex-row items-center pl-4 pr-6 text-2xl text-gray-700 hover:font-bold hover:text-gray-900 aria-[current=page]:border-l-2 aria-[current=page]:border-gray-500 aria-[current=page]:pl-3.5 aria-[current=page]:font-bold aria-[current=page]:text-gray-900 min-[960px]:pl-6 min-[960px]:text-base min-[960px]:aria-[current=page]:pl-5.5"
                  >
                    {t('sidebar.stock')}
                  </NavLink>
                  {isScopeAuthorized(user, 'scope.brand.analytics.view') ? (
                    <NavLink
                      to={`/${brandId}/analytics${location.search}`}
                      className="flex flex-row items-center pl-4 pr-6 text-2xl text-gray-700 hover:font-bold hover:text-gray-900 aria-[current=page]:border-l-2 aria-[current=page]:border-gray-500 aria-[current=page]:pl-3.5 aria-[current=page]:font-bold aria-[current=page]:text-gray-900 min-[960px]:pl-6 min-[960px]:text-base min-[960px]:aria-[current=page]:pl-5.5"
                    >
                      {t('sidebar.analytics')}
                    </NavLink>
                  ) : null}
                  {isScopeAuthorized(user, 'scope.brand.billing.view') ? (
                    <NavLink
                      to={`/${brandId}/billing${location.search}`}
                      className="flex flex-row items-center pl-4 pr-6 text-2xl text-gray-700 hover:font-bold hover:text-gray-900 aria-[current=page]:border-l-2 aria-[current=page]:border-gray-500 aria-[current=page]:pl-3.5 aria-[current=page]:font-bold aria-[current=page]:text-gray-900 min-[960px]:pl-6 min-[960px]:text-base min-[960px]:aria-[current=page]:pl-5.5"
                    >
                      {t('sidebar.billing')}
                    </NavLink>
                  ) : null}
                </div>
                <div className="flex flex-col space-y-4">
                  <NavLink
                    to={`/${brandId}/faq${location.search}`}
                    className="flex flex-row items-center pl-4 pr-6 text-sm text-gray-700 hover:font-bold hover:text-gray-900 aria-[current=page]:border-l-2 aria-[current=page]:border-gray-500 aria-[current=page]:pl-3.5 aria-[current=page]:font-bold aria-[current=page]:text-gray-900 min-[960px]:pl-6 min-[960px]:aria-[current=page]:pl-5.5"
                  >
                    {t('sidebar.faq')}
                  </NavLink>
                  <a
                    onClick={displayHelp}
                    className="flex flex-row items-center pl-4 pr-6 text-sm text-gray-700 hover:font-bold hover:text-gray-900 aria-[current=page]:border-l-2 aria-[current=page]:border-gray-500 aria-[current=page]:pl-3.5 aria-[current=page]:font-bold min-[960px]:pl-6 min-[960px]:aria-[current=page]:pl-5.5"
                  >
                    {t('sidebar.contact_us')}
                  </a>
                </div>
              </div>
              <SalesSelector />
              <OnboardingSurvey />
            </section>
            <div className="flex w-0 flex-1 flex-col overflow-hidden">
              <MobileSidebar />
              <Routes>
                <Route path="orders/:orderId?/*" element={<Orders />} />
                <Route path="home/*" element={<HomeScreen />} />
                <Route path="settings/*" element={<SettingsScreen />} />
                <Route
                  path="billing/*"
                  element={
                    <ProtectedRouteWithScopes scope="scope.brand.billing.view">
                      <BillingScreen />
                    </ProtectedRouteWithScopes>
                  }
                />
                <Route path="stock/*" element={<StockScreen />} />
                <Route
                  path="analytics"
                  element={
                    <ProtectedRouteWithScopes scope="scope.brand.analytics.view">
                      <AnalyticsScreen />
                    </ProtectedRouteWithScopes>
                  }
                />
                <Route path="faq/*" element={<FAQScreen />} />
                <Route
                  path="*"
                  element={<Navigate to={`/${brandId}/home`} />}
                />
              </Routes>
            </div>
          </div>
        }
      />
    </Routes>
  );
};
