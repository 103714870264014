import { Trans, useTranslation } from 'react-i18next';

import Alert from '@appchoose/alert';
import Button from '@appchoose/button';
import {
  ModalClose,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@appchoose/modal';

import {
  CouriersQuery,
  OrdersByTrackingQuery,
} from '../../types/generated-new';
import { track } from '../../utils/analytics';
import { openCrispChat } from '../../utils/crisp';
import { Courier } from '../icons/courier';
import { OrderDetails } from './order-details';

type ModalAddTrackingAlreadyAssignedSameRecipientProps = {
  detectedOrders: OrdersByTrackingQuery['ordersByTracking'];
  isPending: boolean;
  onConfirm: () => void;
  trackingInfo: {
    trackingNumber: string;
    courier: CouriersQuery['searchCouriers'][number] | null;
    orderId: string;
  };
};

export const ModalAddTrackingAlreadyAssignedSameRecipient: React.FC<
  ModalAddTrackingAlreadyAssignedSameRecipientProps
> = ({ detectedOrders, isPending, onConfirm, trackingInfo }) => {
  const { t } = useTranslation();

  return (
    <div className="flex h-full flex-col overflow-hidden">
      <div className="flex-auto overflow-y-auto p-6 md:p-10">
        <ModalHeader>
          <ModalTitle>
            {t(
              'order.tracking.modal_add_tracking_number.confirm_add_tracking_number_and_grouping'
            )}
          </ModalTitle>
        </ModalHeader>
        <div className="space-y-10">
          <Alert
            appearance="default"
            size="small"
            icon="information"
            title={t(
              'order.tracking.modal_add_tracking_number.another_order_same_customer_same_tracking'
            )}
          >
            <p className="text-sm text-gray-700">
              {t(
                'order.tracking.modal_add_tracking_number.orders_grouped_together_same_parcel'
              )}
            </p>
          </Alert>
          <div className="space-y-2">
            <p className="text-sm text-gray-900">
              <Trans
                t={t}
                i18nKey="order.tracking.x_order_of"
                values={{
                  customer: detectedOrders?.[0]?.recipient?.fullName,
                  count: detectedOrders.length,
                }}
                components={{ b: <b className="text-gray-900" /> }}
              />
            </p>
            <div className="divide-y divide-gray-300 rounded border border-gray-300">
              <OrderDetails
                order={{
                  id: trackingInfo.orderId,
                  parcels: [
                    {
                      trackingCourier: {
                        logoUrl: trackingInfo.courier?.logoUrl ?? '',
                        name: trackingInfo.courier?.name ?? '',
                      },
                      trackingNumber: trackingInfo.trackingNumber,
                    },
                  ],
                }}
                orderIdStyle="success"
              />
            </div>
            <div className="flex justify-end">
              <div className="-my-6 mr-4 inline-flex rounded-full border border-[#C6E7D5] bg-[#ECF7F1] p-3">
                <Courier className="text-green-600" />
              </div>
            </div>
            <ul className="divide-y divide-gray-300 rounded border border-gray-300">
              {detectedOrders.map((order) => (
                <li key={order.id}>
                  <OrderDetails order={order} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <ModalFooter className="justify-between">
        <div className="flex items-center space-x-1 text-sm">
          <span className="text-gray-900">
            {t('order.tracking.modal_add_tracking_number.have_a_problem')}
          </span>
          <button
            type="button"
            className="font-semibold text-green-900"
            onClick={() => {
              track('OpenHelpChat', {
                from: 'ModalTrackingAlreadyAssignedSameRecipient',
              });
              openCrispChat();
            }}
          >
            {t('contact_us')}
          </button>
        </div>
        <div className="space-x-6">
          <ModalClose asChild>
            <Button type="button" appearance="outline" disabled={isPending}>
              {t('cancel')}
            </Button>
          </ModalClose>

          <Button type="button" onClick={onConfirm} disabled={isPending}>
            {t('order.tracking.modal_add_tracking_number.group_orders')}
          </Button>
        </div>
      </ModalFooter>
    </div>
  );
};
