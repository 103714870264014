import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import { useRecoilState } from 'recoil';

import { Address, getCountryFromCountryCode } from '@appchoose/address';
import AddressFormFields from '@appchoose/address-form-fields';
import Button from '@appchoose/button';
import { Form } from '@appchoose/form';
import SearchableSelect, {
  SearchableSelectOption,
} from '@appchoose/searchable-select';
import i18n from 'i18next';

import { brandState } from '../../stores/brand';
import { StoreRegion, useUpdateSellerMutation } from '../../types/generated';
import {
  Service,
  ServiceOption,
  frDeliveryServices,
  getActionableServices,
  mapServiceOptionsToServices,
  otherDeliveryImage,
  usDeliveryServices,
} from '../../types/services';
import { searchCountry } from '../../utils/address';
import { getCountryOptions } from '../../utils/address-options';
import { EditServices } from '../edit-services/edit-services';
import { ShippingDelayFormFields } from '../shipping-delay-form-fields/shipping-delay-form-fields';

type SettingsDeliveryProps = {
  onPostSave: () => void;
};

export type SettingsDeliveryFormData = {
  services: ServiceOption[];
  shipping_country: string;
  deliveryDelaysMin: number;
  deliveryDelaysMax: number;
} & Address;

const ShippingCountryNoResult: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="px-4 py-2 text-sm text-gray-500">
      {t('address.fields.country.no_results')}
    </div>
  );
};

export const SettingsDelivery: React.FC<SettingsDeliveryProps> = ({
  onPostSave,
}) => {
  const [brand, setBrand] = useRecoilState(brandState);
  const { t } = useTranslation();

  const { mutateAsync: updateSellerMutation } = useUpdateSellerMutation();

  const deliveryServices =
    brand?.store === StoreRegion.Us ? usDeliveryServices : frDeliveryServices;

  const defaultDeliveryServices = getActionableServices(
    deliveryServices,
    brand?.delivery_services as Service[] | undefined,
    otherDeliveryImage
  );

  const defaultShippingCountry = getCountryFromCountryCode(
    brand?.shipping_country ?? ''
  );

  const shippingCountryOptions = getCountryOptions();
  const [shippingCountry, setShippingCountry] = useState<
    SearchableSelectOption | undefined
  >(
    shippingCountryOptions.find((o) => o.value === defaultShippingCountry?.code)
  );

  const form = useForm<SettingsDeliveryFormData>({
    mode: 'onTouched',
    defaultValues: {
      shipping_country: brand?.shipping_country ?? '',
      services: defaultDeliveryServices,
      name: brand?.return_address?.name ?? '',
      street: brand?.return_address?.street ?? '',
      street2: brand?.return_address?.street2 ?? '',
      bp: brand?.return_address?.bp ?? '',
      city: brand?.return_address?.city ?? '',
      province: brand?.return_address?.province ?? '',
      country: brand?.return_address?.country
        ? brand?.return_address?.country
        : (getCountryFromCountryCode(
            (brand?.return_address?.countryCode as string | null) ?? ''
          )?.fr ??
          (brand?.store === StoreRegion.Us
            ? getCountryFromCountryCode('US').en
            : getCountryFromCountryCode('FR').fr)),
      countryCode: brand?.return_address?.countryCode
        ? brand?.return_address?.countryCode
        : brand?.store === StoreRegion.Us
          ? 'US'
          : 'FR',
      deliveryDelaysMin: brand?.min_delivery_delay ?? undefined,
      deliveryDelaysMax: brand?.max_delivery_delay ?? undefined,
    },
  });

  const onSubmit = (data: SettingsDeliveryFormData) => {
    if (!brand) return;
    const {
      name,
      street,
      street2,
      bp,
      city,
      country,
      countryCode,
      services,
      province,
      shipping_country,
      deliveryDelaysMax,
      deliveryDelaysMin,
    } = data;
    const returnAddress = {
      name,
      street,
      street2,
      bp,
      city,
      country,
      countryCode,
      province,
    };
    const selectedServices = mapServiceOptionsToServices(
      services.filter((s) => s.selected)
    );
    setBrand({
      ...brand,
      min_delivery_delay: deliveryDelaysMin,
      max_delivery_delay: deliveryDelaysMax,
      shipping_country: shipping_country,
      return_address: returnAddress,
      delivery_services: selectedServices,
    });
    updateSellerMutation({
      updateSeller: {
        delivery_services: selectedServices,
        return_address: returnAddress,
        shipping_country: shipping_country,
        min_delivery_delay: deliveryDelaysMin,
        max_delivery_delay: deliveryDelaysMax,
      },
    });
    onPostSave();
  };

  useUpdateEffect(() => {
    form.setValue('shipping_country', shippingCountry?.value ?? '');
  }, [shippingCountry]);

  form.watch('shipping_country');

  return (
    <div className="max-w-2.5xl pb-6">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="mb-10 space-y-14">
            <div>
              <h3 className="mb-8 text-2xl font-bold">
                {t('settings.delivery_tabs.title')}
              </h3>
              <h6 className="mb-2 mt-6 text-xs font-semibold uppercase tracking-wider text-gray-700">
                {t('settings.delivery_tabs.country')}
              </h6>
              <SearchableSelect
                defaultValue={shippingCountry}
                placeholder={t('address.fields.country.placeholder')}
                noResults={ShippingCountryNoResult}
                emptyValue={t('address.fields.country.empty_field')}
                search={(term: string) =>
                  searchCountry(
                    term,
                    shippingCountryOptions,
                    shippingCountryOptions
                  )
                }
                onSelect={(selectedCountry: SearchableSelectOption) => {
                  setShippingCountry(selectedCountry);
                }}
              />
              <h6 className="mb-2 mt-6 text-xs font-semibold uppercase tracking-wider text-gray-700">
                {t('settings.delivery_tabs.services')}
              </h6>
              <EditServices type="delivery" />
            </div>
          </div>
          <div>
            <h3 className="mb-8 text-2xl font-bold">
              {t('settings.delivery_tabs.delays')}
            </h3>
            <ShippingDelayFormFields />
          </div>
          <div className="mb-10">
            <h3 className="mb-8 text-2xl font-bold">
              {t('settings.delivery_tabs.return')}
            </h3>
            <AddressFormFields
              googleMapsApiKey={
                import.meta.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''
              }
              locale={i18n.language}
              showCompany={true}
              showName={false}
              showPhone={false}
              translations={{
                suggestions: t('suggestions'),
                fieldsNameValidationErrorsRequired: t(
                  'address.fields.name.validation_errors.required'
                ),
                fieldsNameValidationErrorsMaxLength: t(
                  'address.fields.name.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsFirstnameValidationErrorsRequired: t(
                  'address.fields.firstname.validation_errors.required'
                ),
                fieldsFirstnameValidationErrorsMaxLength: t(
                  'address.fields.firstname.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsLastnameValidationErrorsRequired: t(
                  'address.fields.lastname.validation_errors.required'
                ),
                fieldsLastnameValidationErrorsMaxLength: t(
                  'address.fields.lastname.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsPhoneValidationErrorsRequired: t(
                  'address.fields.phone.validation_errors.required'
                ),
                fieldsPhoneValidationErrorsMaxLength: t(
                  'address.fields.phone.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsStreetValidationErrorsRequired: t(
                  'address.fields.street.validation_errors.required'
                ),
                fieldsStreetValidationErrorsMaxLength: t(
                  'address.fields.street.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsStreet2ValidationErrorsMaxLength: t(
                  'address.fields.street2.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsBpValidationErrorsRequired: t(
                  'address.fields.bp.validation_errors.required'
                ),
                fieldsBpValidationErrorsMaxLength: t(
                  'address.fields.bp.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsCityValidationErrorsRequired: t(
                  'address.fields.city.validation_errors.required'
                ),
                fieldsCityValidationErrorsMaxLength: t(
                  'address.fields.city.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsProvinceValidationErrorsRequired: t(
                  'address.fields.province.validation_errors.required'
                ),
                fieldsProvinceValidationErrorsMaxLength: t(
                  'address.fields.province.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsCountryValidationErrorsRequired: t(
                  'address.fields.country.validation_errors.required'
                ),
                fieldsNameLabel: t('address.fields.name.label_return'),
                fieldsNamePlaceholder: t('address.fields.name.placeholder'),
                fieldsFirstnameLabel: t('address.fields.firstname.label'),
                fieldsFirstnamePlaceholder: t(
                  'address.fields.firstname.placeholder'
                ),
                fieldsLastnameLabel: t('address.fields.lastname.label'),
                fieldsLastnamePlaceholder: t(
                  'address.fields.lastname.placeholder'
                ),
                fieldsPhoneLabel: t('address.fields.phone.label'),
                fieldsPhonePlaceholder: t('address.fields.phone.placeholder'),
                fieldsStreetLabel: t('address.fields.street.label'),
                fieldsStreetPlaceholder: t('address.fields.street.placeholder'),
                fieldsStreet2Label: t('address.fields.street2.label'),
                fieldsStreet2Placeholder: t(
                  'address.fields.street2.placeholder'
                ),
                fieldsBpLabel: t('address.fields.bp.label'),
                fieldsBpPlaceholder: '',
                fieldsCityLabel: t('address.fields.city.label'),
                fieldsCityPlaceholder: '',
                fieldsProvinceLabel: t('address.fields.province.label'),
                fieldsProvincePlaceholder: '',
                fieldsProvinceEmptyField: t(
                  'address.fields.province.empty_field'
                ),
                fieldsCountryLabel: t('address.fields.country.label'),
                fieldsCountryPlaceholder: t(
                  'address.fields.country.placeholder'
                ),
                fieldsCountryPlaceholderSearch: t(
                  'address.fields.country.placeholder_search'
                ),
                fieldsCountryEmptyField: t(
                  'address.fields.country.empty_field'
                ),
                fieldsCountryNoResults: t('address.fields.country.no_results'),
              }}
            />
          </div>
          <Button type="submit">{t('save')}</Button>
        </form>
      </Form>
    </div>
  );
};

SettingsDelivery.displayName = 'SettingsDelivery';
