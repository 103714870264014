import React from 'react';

import Illustration from '@appchoose/illustration';

import './sale-info.scss';

type SaleInfoProps = {
  variant: 'sale-ready' | 'invoice-waiting' | 'invoice-ready';
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
};

export const SaleInfo: React.FC<SaleInfoProps> = ({
  variant,
  title,
  subtitle,
  children,
}: SaleInfoProps) => {
  const getIllustration = () => {
    if (variant === 'sale-ready') {
      return <Illustration illustration="desktopOn" />;
    }
    if (variant === 'invoice-ready') {
      return <Illustration illustration="desktopMoney" />;
    }
    return <Illustration illustration="desktopChecked" />;
  };

  return (
    <div className="sale-info">
      {getIllustration()}
      <h4>{title}</h4>
      {subtitle ? <h5>{subtitle}</h5> : null}
      {children}
    </div>
  );
};

SaleInfo.displayName = 'SaleInfo';
