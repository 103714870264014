import { useEffect, useState } from 'react';
import { FileWithPath } from 'react-dropzone';

import { useAuth0 } from '@auth0/auth0-react';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import extractFiles from 'extract-files/extractFiles.mjs';
import isExtractableFile from 'extract-files/isExtractableFile.mjs';

import {
  useGenerateProfilePictureUploadUrlMutation,
  useUpdateProfilePictureMutation,
} from '../types/generated-new';
import {
  Exact,
  UploadUrlDocument,
  UploadUrlQuery,
  UploadUrlQueryVariables,
  useUploadCsvFileMutation,
} from './../types/generated';

export const useMultipartFetchData = <TData, TVariables>(
  query: string,
  options?: RequestInit['headers']
): ((variables?: TVariables) => Promise<TData>) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  return async (variables?: TVariables) => {
    const headersInit: HeadersInit = {
      'Apollographql-Client-Name': 'brand-v2',
      'Apollo-Require-Preflight': 'true',
      'X-FROM': document.location.pathname,
      ...options,
    };
    if (isAuthenticated) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      headersInit['Authorization'] = await getAccessTokenSilently();
    }

    const { files } = extractFiles({ variables: variables }, isExtractableFile);

    const formData = new FormData();
    formData.append(
      'operations',
      JSON.stringify({
        query,
        variables,
      })
    );
    if (files.size) {
      const map: Record<string, string[]> = {};
      let i = 0;
      files.forEach((paths) => {
        map[++i] = paths;
      });
      formData.append('map', JSON.stringify(map));

      i = 0;
      files.forEach((_paths, file: File) => {
        'name' in file
          ? formData.append(String(++i), file, file.name)
          : formData.append(String(++i), file);
      });
    }

    const res = await fetch(import.meta.env.REACT_APP_API_URL as string, {
      method: 'POST',
      headers: headersInit,
      body: formData,
    });

    const json = await res.json();

    if (json.errors) {
      const { message } = json.errors[0] as Error;

      throw new Error(message);
    }

    return json.data as TData;
  };
};

export const useUploadUrlMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UploadUrlQuery,
    TError,
    UploadUrlQueryVariables,
    TContext
  >
) => {
  return useMutation<UploadUrlQuery, TError, UploadUrlQueryVariables, TContext>(
    {
      mutationKey: ['UploadUrlMutation'],
      mutationFn: useMultipartFetchData<
        UploadUrlQuery,
        UploadUrlQueryVariables
      >(UploadUrlDocument),
      ...options,
    }
  );
};

const uploadFile = (signedUrl: string, upload: FileWithPath) => {
  return fetch(signedUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': upload.type,
      'Cache-Control': 'no-cache',
    },
    body: upload,
  }).then((response) => {
    if (!response.ok) throw response;
    return response;
  });
};

export type UploadCsvMutation = {
  uploadedCsvUrl?: string | null | undefined;
};

export type UploadCsvMutationVariables = Exact<{
  saleId: string;
  file: FileWithPath;
}>;

/**
 * Uploads a CSV file
 */
export const useUploadCsvMutation = (): [
  (variables: UploadCsvMutationVariables) => Promise<void>,
  {
    data: UploadCsvMutation | undefined;
    loading: boolean;
    error: Error | undefined;
  },
] => {
  const [data, setData] = useState<UploadCsvMutation | undefined>();
  const [error, setError] = useState<Error | undefined>();
  const [loading, setLoading] = useState(false);

  const { mutateAsync: uploadCsvUrl, error: uploadCsvUrlError } =
    useUploadUrlMutation();

  const {
    mutateAsync: uploadCsvFileMutation,
    data: uploadCsvFileData,
    error: uploadCsvFileError,
  } = useUploadCsvFileMutation();

  useEffect(() => {
    if (uploadCsvUrlError instanceof Error) {
      setError(uploadCsvUrlError);
    }
  }, [uploadCsvUrlError]);

  useEffect(() => {
    if (uploadCsvFileError instanceof Error) {
      setError(uploadCsvFileError);
    }
  }, [uploadCsvFileError]);

  useEffect(() => {
    if (uploadCsvFileData?.uploadCsvFile) {
      setData({
        uploadedCsvUrl: uploadCsvFileData?.uploadCsvFile,
      });
    }
  }, [uploadCsvFileData]);

  const uploadCsvMutation = async (variables: UploadCsvMutationVariables) => {
    setLoading(true);

    const { getUploadUrl } = await uploadCsvUrl({
      upload: variables.file,
    });

    if (getUploadUrl) {
      try {
        await uploadFile(getUploadUrl.url as string, variables.file);
      } catch (error) {
        setError(error as Error);
      }

      await uploadCsvFileMutation({
        saleId: variables.saleId,
        url: getUploadUrl.key as string,
      });
    }

    setLoading(false);
  };

  return [uploadCsvMutation, { data, loading, error }];
};

export type UploadPictureMutation = {
  uploadedPictureUrl?: string | null | undefined;
};

export type UploadPictureMutationVariables = Exact<{
  file: File;
}>;

/**
 * Uploads a picture file
 */
export const useUploadPictureMutation = (): [
  (variables: UploadPictureMutationVariables) => Promise<void>,
  {
    data: UploadPictureMutation | undefined;
    loading: boolean;
    error: Error | undefined;
  },
] => {
  const [data, setData] = useState<UploadPictureMutation | undefined>();
  const [error, setError] = useState<Error | undefined>();
  const [loading, setLoading] = useState(false);

  const {
    mutateAsync: uploadProfileUrlMutation,
    error: uploadProfileUrlError,
  } = useGenerateProfilePictureUploadUrlMutation();

  const {
    mutateAsync: updateProfilePictureMutation,
    error: updateProfilePictureError,
  } = useUpdateProfilePictureMutation();

  useEffect(() => {
    if (uploadProfileUrlError instanceof Error) {
      setError(uploadProfileUrlError);
    }
  }, [uploadProfileUrlError]);

  useEffect(() => {
    if (updateProfilePictureError instanceof Error) {
      setError(updateProfilePictureError);
    }
  }, [updateProfilePictureError]);

  const uploadPictureMutation = async (
    variables: UploadPictureMutationVariables
  ) => {
    setLoading(true);

    const { generateProfilePictureUploadUrl } = await uploadProfileUrlMutation({
      upload: {
        fileName: variables.file.name,
        fileMimeType: variables.file.type,
        fileSize: variables.file.size,
      },
    });

    if (generateProfilePictureUploadUrl) {
      try {
        await uploadFile(generateProfilePictureUploadUrl.url, variables.file);

        setData({
          uploadedPictureUrl: generateProfilePictureUploadUrl.url,
        });
      } catch (error) {
        setError(error as Error);
      }

      await updateProfilePictureMutation({
        url: generateProfilePictureUploadUrl.url,
      });
    }

    setLoading(false);
  };

  return [uploadPictureMutation, { data, loading, error }];
};

export type TimeZone = {
  olsonName: string;
  description: string;
};

export type GetTimezonesQuery = { data: { timeZones: TimeZone[] } };

export type GetTimezonesQueryVariables = {
  locale: string;
};

export const useGetTimezonesQuery = (): [
  (variables: GetTimezonesQueryVariables) => Promise<void>,
  {
    data: GetTimezonesQuery | undefined;
    loading: boolean;
    error: Error | undefined;
  },
] => {
  const [data, setData] = useState<GetTimezonesQuery | undefined>();
  const [error, setError] = useState<Error | undefined>();
  const [loading, setLoading] = useState(false);

  const getTimezones = async (variables: GetTimezonesQueryVariables) => {
    setLoading(true);

    try {
      const response = await fetch('https://atlas.shopifycloud.com/graphql', {
        headers: {
          'content-type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({
          operationName: 'timeZones',
          query: `query timeZones($locale: SupportedLocale!) {
            timeZones(locale: $locale) {
              olsonName,
              description
            }
          }`,
          variables: {
            locale: variables.locale.toUpperCase(),
          },
        }),
        method: 'POST',
      });

      if (!response.ok) {
        throw new Error(await response.text());
      }

      setData((await response.json()) as GetTimezonesQuery);
    } catch (e) {
      setError(e as Error);
    }

    setLoading(false);
  };

  return [getTimezones, { data, loading, error }];
};
